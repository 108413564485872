<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">

      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Coupon</h4>
          <p class="card-description">
            Product <code>Coupons</code>
          </p>
          <div class="table-responsive">
            <ag-grid-vue style="width: 100%; height: 500px;" class="ag-theme-alpine" :columnDefs="columnDefs"
              :rowData="coupons" :pagination="true">
            </ag-grid-vue>

          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- content-wrapper ends -->
</template>

<script>
import { ref, onMounted } from 'vue';
import { useToast } from "vue-toastification";
import { AgGridVue } from "ag-grid-vue3";
import { useCookies } from "vue3-cookies";

export default {
  name: "coupons",
  components: {
    AgGridVue,
  },

  setup() {
    const coupons = ref([]);
    const toast = useToast();
    const { cookies } = useCookies();
    const getcoupons = async () => {
      let res = await axios.get("/admin/get-all-coupons");
      coupons.value = res.data;
    }

    const editcoupon = async (value) => {
      cookies.set('coupv', value);
      window.location.href = '/admin/edit-coupon';
    }

    const deletecoupon = async (id) => {
      await axios.delete("/admin/delete-coupon/" + id);
      getcoupons();
      toast.success("coupon deleted successfully!", {
        timeout: 2000
      });
    }

    onMounted(getcoupons());

    return {
      coupons, toast,
      editcoupon, deletecoupon,
      columnDefs: [
     
        { headerName: "Name", field: "name" },
        { headerName: "Details", field: "details" },
        { headerName: "Type", field: "type" },
        { headerName: "Value", field: "value" },
        { 
          headerName: "Validity", 
          field: "validity",
          cellRenderer: function (params) {            
            return params.data.startfrom + ' - ' + params.data.endsat;
          },

        },
    
        {
          headerName: "Edit",
          field: 'edit',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-warning");
            input.innerHTML = "Edit";
            input.addEventListener("click", function (event) {
              editcoupon(params.data.id);
            });
            return input;
          },

        },
        {
          headerName: "Delete",
          field: 'delete',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-danger");
            input.innerHTML = "Delete";
            input.addEventListener("click", function (event) {
              deletecoupon(params.data.id);
            });
            return input;
          },

        },
      ],

    }
  },

};


</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
