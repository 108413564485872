<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Orders</h4>
          <p class="card-description">All <code>Orders</code></p>
          <div class="table-responsive">
            <ag-grid-vue
              style="width: 100%; height: 500px"
              class="ag-theme-alpine"
              :columnDefs="columnDefs"
              :rowData="orders"
              :pagination="true"
            >
            </ag-grid-vue>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- content-wrapper ends -->
</template>

<script>
import { ref, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { AgGridVue } from "ag-grid-vue3";
import { useCookies } from "vue3-cookies";

export default {
  name: "Orders",
  components: {
    AgGridVue,
  },

  setup() {
    const orders = ref([]);
    const toast = useToast();
    const { cookies } = useCookies();
    const getorders = async () => {
      let res = await axios.get("/admin/get-all-orders");
      orders.value = res.data;
    };

    const invoiceDetails = async (value) => {
      cookies.set("prodv", value);
      window.location.href = "/admin/order-details/"+ value;
    }

    onMounted(getorders());

    return {
      orders,
      toast,
      invoiceDetails,
      columnDefs: [
        { headerName: "Invoice", field: "invoice_no", filter: 'agTextColumnFilter', },
        { headerName: "Date", field: "created_at" },
        { headerName: "Amount", field: "total" },
        {
          headerName: "Activity",
          field: "activity",
          cellRenderer: "btnCellRenderer",
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-outline-primary");
            input.innerHTML = "Details";
            input.addEventListener("click", function (event) {
              invoiceDetails(params.data.id);
            });
            return input;
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
