<template>
    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">

            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Shipping</h4>
                    <p class="card-description">
                        Create/update Shipping
                    </p>
                    <VeeForm class="pt-3" @submit="storeshipping">

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Place Name</label>

                                    <VeeField name="shippingname" type="text" class="form-control form-control-lg"
                                        placeholder="Shipping Place Name" v-model="shipping.shippingname" rules="required" />
                                    <ErrorMessage name="shippingname" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Notes</label>
                                    <VeeField name="shippingnotes" type="text" class="form-control form-control-lg"
                                        placeholder="shipping Notes" v-model="shipping.shippingnotes" rules="" />
                                    <ErrorMessage name="shippingnotes" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Value</label>

                                    <VeeField name="shippingvalue" type="number" step="0.01"
                                        class="form-control form-control-lg" placeholder="Shipping Value"
                                        v-model="shipping.shippingvalue" rules="required" />
                                    <ErrorMessage name="shippingvalue" />
                                </div>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary me-2">Submit</button>
                        <button class="btn btn-light">Cancel</button>
                    </VeeForm>
                </div>
            </div>

        </div>
    </div>
    <!-- content-wrapper ends -->
</template>


<script>
import { ref, onMounted, reactive } from 'vue';
import { useToast } from "vue-toastification";


export default {
    name: "Createshipping",

    props: {
        msg: String,
    },
    setup() {
        const toast = useToast();
        const errors = ref([]);
        const shipping = reactive({
            shippingname: '',
            shippingnotes: '',
            shippingvalue: '',
        });
        

        const storeshipping = async () => {
            try {
                const res = await axios.post("/admin/store-shipping", shipping)
                toast.success("shipping created successfully!", {
                    timeout: 2000
                });
                // console.log(res)
                window.location.href = '/admin/shippings';
            } catch (e) {
                if (e.response.status == 422) {
                    var data = [];
                    for (const key in e.response.data.errors) {
                        data.push(e.response.data.errors[key][0]);
                    }
                    errors.value = data;
                }
            }
        }

        const formReset = () => {
            shipping.shippingname = '';
            shipping.shippingnotes = '';
            shipping.shippingvalue = 0;
        }

        return {
            shipping, storeshipping,
            errors, toast,
        }
    },
};
</script>


<style lang="scss">
@import "../../../../../node_modules/vue-good-table-next/src/styles/style.scss";
@import "../../../../../node_modules/vue-toastification/dist/index.css";
</style>
