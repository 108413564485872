<template>
  <div class="content-wrapper d-flex align-items-center auth px-0">
    <div class="row w-100 mx-0">
      <div class="col-lg-4 mx-auto">
        <div class="auth-form-light text-left py-5 px-4 px-sm-5">
          <div class="brand-logo">
            <img v-bind:src="logo" />
          </div>
          <h4>Let's get started</h4>
          <h6 class="fw-light">Sign in to continue.</h6>
          <VeeForm class="pt-3" @submit="onSubmit">
            <div class="input-group mb-4 row">
              <div class="input-group-prepend">
                <div class="input-group-text" style="height: 100%">88</div>
              </div>
              <VeeField
                name="username"
                type="text"
                class="form-control form-control-lg"
                placeholder="Username/Mobile no"
                v-model="login.username"
                rules="required"
              />
              <ErrorMessage name="username" />
            </div>
            <div class="form-group">
              <VeeField
                name="password"
                type="password"
                class="form-control form-control-lg"
                v-model="login.password"
                placeholder="Password"
                rules="required|min:4"
              />
              <ErrorMessage name="password" />
            </div>
            <div class="mt-3">
              <button
                class="
                  btn btn-block btn-primary btn-lg
                  font-weight-medium
                  auth-form-btn
                "
                type="submit"
              >
                SIGN IN
              </button>
              <a :href="'/'" class="btn btn-light btn-lg">Cancel</a>
            </div>
            <div class="my-2 d-flex justify-content-between align-items-center">
              <a href="#" class="auth-link text-black">Forgot password?</a>
              <a :href="'/register'" class="auth-link text-black"
                >Register Now</a
              >
            </div>
          </VeeForm>
        </div>
      </div>
    </div>
  </div>
  <!-- content-wrapper ends -->
</template>
<script>
import { useToast } from "vue-toastification";
export default {
  name: "AdminLogin",
  props: {
    msg: String,
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      count: 0,
      logo: "../assets/img/logo_200.png",
      login: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    onSubmit() {
      this.axios
        .post("/login", this.login)
        .then((response) => {
          // this.toast.info("I'm an info toast!");
          window.location.href = response.data.url;
          // console.log(response.data);
        })
        .catch((error) => {
          this.toast.warning("Username or Password mismatch");
        });
    },
  },
};
</script>
<style scoped>
.auth .brand-logo {
  text-align: center;
}
</style>