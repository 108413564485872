<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">

      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Categories</h4>
          <p class="card-description">
            Product <code>Categories</code>
          </p>
          <div class="table-responsive">
            <ag-grid-vue style="width: 100%; height: 500px;" class="ag-theme-alpine" :columnDefs="columnDefs"
              :rowData="categories" :pagination="true">
            </ag-grid-vue>

          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- content-wrapper ends -->
</template>

<script>
import { ref, onMounted } from 'vue';
import { useToast } from "vue-toastification";
import { AgGridVue } from "ag-grid-vue3";
import { useCookies } from "vue3-cookies";


export default {
  name: "Categories",
  components: {
    AgGridVue,
  },

  setup() {
    const categories = ref([]);
    const toast = useToast();
    const { cookies } = useCookies();

    const getcategories = async () => {
      let res = await axios.get("/admin/get-all-categories");
      categories.value = res.data;
    }

    const editcategory = async (value) => {
      cookies.set('catv', value);
      window.location.href = '/admin/edit-category';
    }

    const deletecategory = async (id) => {
      await axios.delete("/admin/delete-category/" + id);
      getcategories();
      toast.success("Category deleted successfully!", {
        timeout: 2000
      });
    }

    onMounted(getcategories());

    return {
      categories, toast,
      editcategory, deletecategory,
      columnDefs: [
        {
          headerName: "Image",
          field: 'img',
          cellRenderer: 'imgCellRenderer',
          cellRenderer: function (params) {
            var img = document.createElement("img");
            img.src = "../../../../../uploads/categories/" + params.data.featured_img;
            img.setAttribute("class", "img img-responsive");
            img.setAttribute("width", "20%");
            return img;
          },
        },
        { headerName: "Name", field: "name" },
        { headerName: "Details", field: "details" },
        { headerName: "Order", field: "sort" },
        { headerName: "Status", field: "status" },
        {
          headerName: "Edit",
          field: 'edit',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-warning");
            input.innerHTML = "Edit";
            input.addEventListener("click", function (event) {
              editcategory(params.data.id);
            });
            return input;
          },

        },
        {
          headerName: "Delete",
          field: 'delete',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-danger");
            input.innerHTML = "Delete";
            input.addEventListener("click", function (event) {
              deletecategory(params.data.id);
            });
            return input;
          },

        },
      ],

    }
  },

};


</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
