<template>
  <!-- main content -->
  <div class="main-content">
    <div id="wrapper-site">
      <div id="content-wrapper">
        <div id="main">
          <div class="page-home">
            <!-- breadcrumb -->
            <nav class="breadcrumb-bg">
              <div class="container no-index">
                <div class="breadcrumb">
                  <ol>
                    <li>
                      <a :href="'/'">
                        <span>Home</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>Privacy Policy</span>
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
            </nav>
            <div class="container">
              <div class="content">
                <div class="row">
                  <div
                    class="col-sm-8 col-lg-9 col-md-9 flex-xs-first main-blogs"
                  >
                    <h3>Privacy Policy</h3>

                    <div class="late-item">
                      
                      <p>
                        •	We don’t have any third party organization. We are SWAN GROUP and deal our customer directly.  
                      </p>
                      <p>
                        •	Each user accounts must be created under one distinctive registered Cell Phone number, and email address of the user. Multiple accounts cannot be created under the same information as mentioned. In such an event the user cannot avail more than once any of the offers, deals, discount coupons, or Gift Coupons during campaigns or other occasional sales.
                      </p>

                      <p>
                        •	SWAN GROUP reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes & updates. 
                      </p>

                      <p>
                        •	To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed. If you provide us with your credit card information, the information is encrypted using secure socket layer technology (SSL). Although no method of transmission over the Internet or electronic storage is 100% secure, we follow all PCI-DSS requirements and implement additional generally accepted industry standards. We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.
                      </p>
                      <p class="content-title">Product Stock Quantity: </p>
                      <p>
                        50 pcs of each product shown in website. 
Stock out products should be mentioned in the picture or remove from the website.
                        <ol>
                            <li> &nbsp;&nbsp;&nbsp;   •	Registered Address: 200/A, Tejgaon Industrial Area, Dhaka-1208. </li>
                            <li> &nbsp;&nbsp;&nbsp; •	Trade license Number: TRAD/DNCC/012806/2022</li>
                            <li> &nbsp;&nbsp;&nbsp; •	TIN Certificate Number: 000147322-0203</li>
                        </ol>
                      </p>
                      
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>