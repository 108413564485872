import { createWebHistory, createRouter } from "vue-router";

import RegisterComponent from './components/Auth/RegisterComponent.vue';

import Welcome from './components/Welcome.vue';
import ProductDetailsComponent from './components/Pages/ProductDetailsComponent.vue';
import AboutUsComponent from './components/Pages/AboutUsComponent.vue';
import BoardOfDirectorsComponent from './components/Pages/BoardOfDirectorsComponent.vue';
import ContactUsComponent from './components/Pages/ContactUsComponent.vue';
import ProductAllComponent from './components/Pages/ProductAllComponent.vue';
import CategoryDetailsComponent from './components/Pages/CategoryDetailsComponent.vue';
import CartComponent from './components/Pages/CartComponent.vue';
import CheckoutComponent from './components/Pages/CheckoutComponent.vue';

import TermsAndConditionsComponent from './components/Pages/TermsAndConditionsComponent.vue';
import ReturnAndRefundPolicyComponent from './components/Pages/ReturnAndRefundPolicyComponent.vue';
import PrivacyPolicyComponent from './components/Pages/PrivacyPolicyComponent.vue';


//Users
import HomeComponent from './components/Users/HomeComponent.vue';
import InvoiceComponent from './components/Users/InvoiceComponent.vue';

//Admin
import AdminLogin from './components/Admin/Auth/Login.vue';
import Dashboard from './components/Admin/Dashboard.vue';

import CategoryListComponent from './components/Admin/Category/CategoryListComponent.vue';
import CategoryCreateComponent from './components/Admin/Category/CategoryCreateComponent.vue';
import CategoryEditComponent from './components/Admin/Category/CategoryEditComponent.vue';

import AttributeCreateComponent from './components/Admin/Attributes/AttributeCreateComponent.vue';
import AttributeEditComponent from './components/Admin/Attributes/AttributeEditComponent.vue';
import AttributeListComponent from './components/Admin/Attributes/AttributeListComponent.vue';

import ProductListComponent from './components/Admin/Product/ProductListComponent.vue';
import ProductCreateComponent from './components/Admin/Product/ProductCreateComponent.vue';
import ProductEditComponent from './components/Admin/Product/ProductEditComponent.vue';

import BannerListComponent from './components/Admin/Banner/BannerListComponent.vue';
import BannerCreateComponent from './components/Admin/Banner/BannerCreateComponent.vue';

import CouponListComponent from './components/Admin/Coupon/CouponListComponent.vue';
import CouponCreateComponent from './components/Admin/Coupon/CouponCreateComponent.vue';
import CouponEditComponent from './components/Admin/Coupon/CouponEditComponent.vue';

import ShippingListComponent from './components/Admin/Shipping/ShippingListComponent.vue';
import ShippingCreateComponent from './components/Admin/Shipping/ShippingCreateComponent.vue';
import ShippingEditComponent from './components/Admin/Shipping/ShippingEditComponent.vue';

import UserListComponent from './components/Admin/Users/UserListComponent.vue';
import UserCreateComponent from './components/Admin/Users/UserCreateComponent.vue';
import RoleListComponent from './components/Admin/Users/RoleListComponent.vue';
import RoleCreateComponent from './components/Admin/Users/RoleCreateComponent.vue';

import CustomerListComponent from './components/Admin/Customer/CustomerListComponent.vue';
import OrderListComponent from './components/Admin/Orders/OrderListComponent.vue';


const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },

  {
    path: "/register",
    name: "Register",
    component: RegisterComponent,
  },

  {
    path: "/shop/:id",
    name: "CategoryDetails",
    component: CategoryDetailsComponent,
  },

  {
    path: "/all-products",
    name: "AllProducts",
    component: ProductAllComponent,
  },

  {
    path: "/details/:id",
    name: "ProductDetails",
    component: ProductDetailsComponent,
    props: true
  },

  {
    path: "/cart",
    name: "Cart",
    component: CartComponent,
    props: true
  },

  {
    path: "/checkout",
    name: "Checkout",
    component: CheckoutComponent,
    props: true
  },

  {
    path: "/about-us",
    name: "AbouttUs",
    component: AboutUsComponent,
  },

  {
    path: "/board-of-directors",
    name: "BoardOfDirectors",
    component: BoardOfDirectorsComponent,
  },

  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUsComponent,
  },

  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: TermsAndConditionsComponent,
  },

  {
    path: "/return-and-refund-policy",
    name: "ReturnAndRefundPolicy",
    component: ReturnAndRefundPolicyComponent,
  },

  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicyComponent,
  },

  //Users

  {
    path: "/home",
    name: "Home",
    component: HomeComponent,
  },
  {
    path: "/invoice/:key",
    name: "Invoice",
    component: InvoiceComponent,
  },


  //Admin
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/admin/categories",
    name: "Categories",
    component: CategoryListComponent,
  },
  {
    path: "/admin/create-category",
    name: "CreateCategories",
    component: CategoryCreateComponent,
  },
  {
    path: "/admin/edit-category",
    name: "EditCategory",
    component: CategoryEditComponent,
  },
  {
    path: "/admin/attributes",
    name: "Attributes",
    component: AttributeListComponent,
  },
  {
    path: "/admin/create-attribute",
    name: "CreateAttributes",
    component: AttributeCreateComponent,
  },
  {
    path: "/admin/edit-attribute",
    name: "EditAttribute",
    component: AttributeEditComponent,
  },
  {
    path: "/admin/products",
    name: "Products",
    component: ProductListComponent,
  },
  {
    path: "/admin/create-product",
    name: "CreateProduct",
    component: ProductCreateComponent,
  },
  {
    path: "/admin/edit-product",
    name: "EditProduct",
    component: ProductEditComponent,
  },
  {
    path: "/admin/banners",
    name: "Banners",
    component: BannerListComponent,
  },
  {
    path: "/admin/create-banner",
    name: "CreateBanner",
    component: BannerCreateComponent,
  },
  {
    path: "/admin/coupons",
    name: "Coupons",
    component: CouponListComponent,
  },
  {
    path: "/admin/create-coupon",
    name: "CreateCoupon",
    component: CouponCreateComponent,
  },
  {
    path: "/admin/edit-coupon",
    name: "EditCoupon",
    component: CouponEditComponent,
  },

  {
    path: "/admin/shippings",
    name: "Shippings",
    component: ShippingListComponent,
  },
  {
    path: "/admin/create-shipping",
    name: "CreateShipping",
    component: ShippingCreateComponent,
  },
  {
    path: "/admin/edit-shipping",
    name: "EditShipping",
    component: ShippingEditComponent,
  },

  {
    path: "/admin/users",
    name: "Users",
    component: UserListComponent,
  },
  {
    path: "/admin/create-user",
    name: "CreateUser",
    component: UserCreateComponent,
  },
  {
    path: "/admin/roles",
    name: "Roles",
    component: RoleListComponent,
  },
  {
    path: "/admin/create-role",
    name: "CreateRole",
    component: RoleCreateComponent,
  },
  {
    path: "/admin/customers",
    name: "Customer",
    component: CustomerListComponent,
  },
  {
    path: "/admin/orders",
    name: "Orders",
    component: OrderListComponent,
  },
  

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;