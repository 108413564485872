<template>
  <!-- main content -->
  <div class="main-content">
    <div id="wrapper-site">
      <div id="content-wrapper">
        <div id="main">
          <div class="page-home">
            <!-- breadcrumb -->
            <nav class="breadcrumb-bg">
              <div class="container no-index">
                <div class="breadcrumb">
                  <ol>
                    <li>
                      <a :href="'/'">
                        <span>Home</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>All Products</span>
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
            </nav>

            <div class="container">
              <div class="content">
                <div class="row">
                  <div
                    class="
                      sidebar-3 sidebar-collection
                      col-lg-3 col-md-4 col-sm-4
                    "
                  >
                    <!-- category -->
                    <div class="sidebar-block">
                      <div class="title-block">Categories</div>
                      <div class="block-content">
                        <div
                          class="cateTitle hasSubCategory open level1"
                          v-for="(catg, intc) in categories"
                          :key="intc"
                        >
                          <span
                            class="arrow collapse-icons collapsed"
                            data-toggle="collapse"
                            data-target="#livingroom"
                          >
                            <i class="zmdi zmdi-minus"></i>
                            <i class="zmdi zmdi-plus"></i>
                          </span>
                          <a class="cateItem" href="#">{{ catg.name }}</a>
                          <div
                            class="subCategory collapse"
                            id="livingroom"
                            aria-expanded="true"
                            role="status"
                          >
                            <div
                              class="cateTitle"
                              v-for="(catpro, intcp) in catg.products"
                              :key="intcp"
                            >
                              <a
                                :href="'/details/' + catpro.productdetails.slug"
                                class="cateItem"
                                >{{ catpro.product_name }}</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-8 col-lg-9 col-md-8 product-container">
                    <div class="tab-content product-items">
                      <div
                        id="list"
                        class="related tab-pane fade in active show"
                      >
                        <div class="row">
                          <div
                            class="item col-md-12"
                            v-for="(prod, indp) in products"
                            :key="indp"
                          >
                            <div
                              class="
                                product-miniature
                                item-one
                                first-item
                              "
                            >
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="thumbnail-container border">
                                    <a :href="'/details/' + prod.slug">
                                      <img v-if="prod.featured_img"
                                        class="img-fluid"
                                        :src="
                                          '../../../uploads/products/' +
                                          prod.featured_img
                                        "
                                        alt="img"
                                      />
                                    </a>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="product-description">
                                    <div class="product-groups">
                                      <div class="product-title">
                                        <a :href="'/details/' + prod.slug">{{
                                          prod.name
                                        }}</a>
                                        <span class="info-stock">
                                          <i
                                            class="fa fa-check-square-o"
                                            aria-hidden="true"
                                          ></i>
                                          In Stock
                                        </span>
                                      </div>

                                      <div class="product-group-price">
                                        <div class="product-price-and-shipping">
                                            <span class="price"> {{ prod.default_size }} </span>
                                          <span class="price">৳{{ prod.selling_price }}</span>
                                        </div>
                                      </div>
                                      <div class="discription" v-for="(pdet, indpd) in prod.details" :key="indpd">
                                        <span v-if="pdet.details_type == 'short'">
                                            <span v-html="pdet.details"></span>
                                        </span>
                                      </div>
                                    </div>
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- end col-md-9-1 -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { watchEffect, ref, onMounted, reactive, computed } from "vue";
export default {
  setup() {
    const route = useRoute();
    const toast = useToast();
    const categories = ref([]);
    const productid = route.params.id;
    const minimumqn = ref([]);
    const customheight = ref([]);
    const products = ref([]);
    const product = reactive({
      prodid: 0,
      length: 0,
      width: 0,
      height: 0,
      price: 0,
      febrics: 0,
      febricid: "",
      febricprice: 0,
      quantity: 1,
      customprice: 0,
      attributedet: 0,
      attributeid: 0,
      attributeprice: 0,
    });
    const productdetails = ref([]);

    const getcategories = async () => {
      let res = await axios.get("/listcategorieswithproducts");
      categories.value = res.data;
    };

    const getproducts = async () => {
      let res = await axios.get("/listproducts");
      products.value = res.data;
    };

    onMounted(getcategories());
    onMounted(getproducts());

    return {
      productid,
      products,
      categories,
    };
  },
};
</script>