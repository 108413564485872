<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">coupon</h4>
          <p class="card-description">Create/update coupon</p>
          <VeeForm class="pt-3" @submit="updatecoupon">
            <div class="form-group">
              <label for="exampleInputUsername1">Name</label>

              <VeeField
                name="couponname"
                type="text"
                class="form-control form-control-lg"
                placeholder="coupon Name"
                v-model="coupon.couponname"
                rules="required"
              />
              <ErrorMessage name="couponname" />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Details</label>
              <VeeField
                name="coupondetails"
                type="text"
                class="form-control form-control-lg"
                placeholder="coupon Details"
                v-model="coupon.coupondetails"
                rules="required"
              />
              <ErrorMessage name="coupondetails" />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1">Start Date</label>
                  <Datepicker
                    v-model="coupon.couponstartfrom"
                    autoApply
                    :format="format"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1">End date</label>
                  <Datepicker
                    v-model="coupon.couponendsat"
                    autoApply
                    :format="format"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1">Discount Type</label>
                  <v-select
                    label="name"
                    :options="coupontype"
                    v-model="coupon.coupontype"
                  ></v-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputUsername1">Value</label>

                  <VeeField
                    name="couponvalue"
                    type="number"
                    step="0.01"
                    class="form-control form-control-md"
                    placeholder="Coupon Value"
                    v-model="coupon.couponvalue"
                    rules="required"
                  />
                  <ErrorMessage name="couponvalue" />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleInputUsername1">Status</label>

              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="coupon.status" />
                  Active
                  <i class="input-helper"></i
                ></label>
              </div>
            </div>

            <button type="submit" class="btn btn-primary me-2">Submit</button>
            <button class="btn btn-light">Cancel</button>
          </VeeForm>
        </div>
      </div>
    </div>
  </div>
  <!-- content-wrapper ends -->
</template>


<script>
import { ref, onMounted, reactive } from "vue";
import { useToast } from "vue-toastification";
import { useCookies } from "vue3-cookies";

export default {
  name: "EditCoupon",

  props: {
    msg: String,
  },
  setup() {
    const toast = useToast();
    const errors = ref([]);
    const { cookies } = useCookies();
    const coupontype = ref(['percent', 'amount']);

    const coupon = reactive({
      couponid: cookies.get("coupv"),
      couponname: "",
      coupondetails: "",
      coupontype: [],
      couponvalue: "",
      couponstartfrom: "",
      couponendsat: "",
    });

    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };

    const getcoupon = async () => {
      let res = await axios.get("/admin/get-coupon/" + coupon.couponid);
      coupon.couponname = res.data.name;
      coupon.coupondetails = res.data.details;
      coupon.coupontype = res.data.type;
      coupon.couponvalue = res.data.value;
      coupon.couponstartfrom = res.data.startfrom;
      coupon.couponendsat = res.data.endsat;
      coupon.status = res.data.status != 'active' ? false : true;
    };

    const updatecoupon = async () => {
      try {
        const res = await axios.post("/admin/update-coupon", coupon);
        toast.success("coupon created successfully!", {
          timeout: 2000,
        });
        // console.log(res)
        getcoupon();
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    };

    const formReset = () => {
      coupon.couponname = "";
      coupon.coupondetails = "";
    };

    onMounted(getcoupon());

    return {
      coupon,
      updatecoupon,
      errors,
      toast,
      coupontype, format,
    };
  },
};
</script>


<style lang="scss">
@import "../../../../../node_modules/vue-good-table-next/src/styles/style.scss";
@import "../../../../../node_modules/vue-toastification/dist/index.css";
</style>
