<template>
  <!-- main content -->
  <div class="main-content">
    <div id="wrapper-site">
      <div id="content-wrapper">
        <!-- breadcrumb -->
        <nav class="breadcrumb-bg">
          <div class="container no-index">
            <div class="breadcrumb">
              <ol>
                <li>
                  <a :href="'/'">
                    <span>Home</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span>About us</span>
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </nav>
        <div id="main">
          <div class="page-home">
            <div class="container">
              <div class="about-us-content">
                <h1 class="title-page">ABOUT SWAN GROUP</h1>
                <div class="row">
                  <div class="col-lg-8 col-md-8 col-sm-8 right">
                    <div class="cms-block f-left" style="margin-top: 1rem">
                      <h4>WHO WE ARE</h4>
                      <p>
                        We are SWAN GROUP engaged in production of Polyurethane
                        Foam,Synthetic Rubber Adhesive (SRA), PU Adhesive, UPR
                        (Unsaturated Polyester Resin) & LA (Lamination
                        Adhesive),Spring, Coir & Re-Bonded Mattresses.
                      </p>
                      <ul>
                        <li>Home Textiles</li>
                        <li>Swan Properties</li>
                      </ul>
                      <br />
                      <h4>WHAT WE DO</h4>
                      <p>
                        Brand name of our products is “SWAN” and are widely
                        known as SWAN FOAM, SWAN
                      </p>

                      <p>
                        <strong>
                          MATTRESSES, SWAN CHEMICALS & SWAN PROPERTIES
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4 left">
                    <img
                      class="img-fluid"
                      :src="'../../../assets/img/logo_720.png'"
                      alt="#" style="width: 75%;padding: 10px 40px;"
                    />
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12 right">
                    <div class="cms-block f-left">
                      <h4>PRODUCTS</h4>
                      <p>
                        <strong>Swan Foam:</strong> Our Brand is very popular
                        and we are particular about using best raw materials of
                        GERMANY / USA. Doing continuous R & D; adopting latest
                        formulation followed by EUROPEAN and AMERICAN
                        manufacturers; we can ensure best quality of our
                        products. <br /><br />
                        <strong>Swan Mattress:</strong> We are pioneer for
                        production of Mattresses (Spring, Re-bonded & Coir). In
                        this field we are also selective for BEST raw materials
                        and machineries, thus can keep QUALITY and became market
                        leader. <br /><br />
                        <strong>Swan Chemicals:</strong> we are producing
                        SYNTHETIC RUBBER ADHESIVE (SRA) & PU ADHESIVE for
                        leather, synthetic leather, wood, formic etc.
                      </p>
                      <br />
                      <ul>
                        <li>
                          UPR (UNSATURATED POLYESTER RESIN) for production of
                          Fiberglass items.
                        </li>
                        <li>
                          LA (LAMINATION ADHESIVE) for packing of food, drink,
                          cosmetic items.
                        </li>
                      </ul>
                      <P>
                        <strong>Sales & Display Centers:</strong> We have 13
                        Show Rooms of our own; more than 100 Agents and 1400
                        Retail Customers (shop owners) throughout Bangladesh.
                        <br /><br />

                        <strong>SWAN Properties:</strong> Engaged in
                        Construction & Sales of quality Residential Apartments &
                        Commercial Spaces. </P
                      ><br />
                      <h4>Other Activities:</h4>
                      <p>
                        Trading (Import & Marketing) of Tiles, Sanitary wares &
                        Fabrics.
                        <br />
                        Trading of Raw Materials for production of Adhesive &
                        Foam.
                      </p>
                      <p>
                        <strong>Total Employers:</strong> 500 Persons (Approx.)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>