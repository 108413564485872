<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">

      <div class="card">
        <div class="card-body">
          <h4 class="card-title">SHipping</h4>
          <p class="card-description">
            Shipping <code>Rate</code>
          </p>
          <div class="table-responsive">
            <ag-grid-vue style="width: 100%; height: 500px;" class="ag-theme-alpine" :columnDefs="columnDefs"
              :rowData="shippings" :pagination="true">
            </ag-grid-vue>

          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- content-wrapper ends -->
</template>

<script>
import { ref, onMounted } from 'vue';
import { useToast } from "vue-toastification";
import { AgGridVue } from "ag-grid-vue3";
import { useCookies } from "vue3-cookies";

export default {
  name: "shippings",
  components: {
    AgGridVue,
  },

  setup() {
    const shippings = ref([]);
    const toast = useToast();
    const { cookies } = useCookies();
    const getshippings = async () => {
      let res = await axios.get("/admin/get-all-shippings");
      shippings.value = res.data;
    }

    const editshipping = async (value) => {
      cookies.set('shipv', value);
      window.location.href = '/admin/edit-shipping';
    }

    const deleteshipping = async (id) => {
      await axios.delete("/admin/delete-shipping/" + id);
      getshippings();
      toast.success("shipping deleted successfully!", {
        timeout: 2000
      });
    }

    onMounted(getshippings());

    return {
      shippings, toast,
      editshipping, deleteshipping,
      columnDefs: [
     
        { headerName: "Name", field: "name" },
        { headerName: "Rate", field: "rate" },
        { headerName: "Notes", field: "notes" },
        { headerName: "Status", field: "status" },  
        {
          headerName: "Edit",
          field: 'edit',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-warning");
            input.innerHTML = "Edit";
            input.addEventListener("click", function (event) {
              editshipping(params.data.id);
            });
            return input;
          },

        },
        {
          headerName: "Delete",
          field: 'delete',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-danger");
            input.innerHTML = "Delete";
            input.addEventListener("click", function (event) {
              deleteshipping(params.data.id);
            });
            return input;
          },

        },
      ],

    }
  },

};


</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
