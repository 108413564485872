<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">

      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Users</h4>
          <p class="card-description">
            Admin <code>Users</code>
          </p>
          <div class="table-responsive">
            <ag-grid-vue style="width: 100%; height: 500px;" class="ag-theme-alpine" :columnDefs="columnDefs"
              :rowData="users" :pagination="true">
            </ag-grid-vue>

          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- content-wrapper ends -->
</template>

<script>
import { ref, onMounted } from 'vue';
import { useToast } from "vue-toastification";
import { AgGridVue } from "ag-grid-vue3";

export default {
  name: "Users",
  components: {
    AgGridVue,
  },

  setup() {
    const users = ref([]);
    const toast = useToast();
    const getusers = async () => {
      let res = await axios.get("/admin/get-all-users");
      users.value = res.data;
    }

    const edituser = async (value) => {
      console.log('this is edit' + value);
    }

    const deleteuser = async (id) => {
      await axios.delete("/admin/delete-user/" + id);
      getusers();
      toast.success("User deleted successfully!", {
        timeout: 2000
      });
    }

    onMounted(getusers());

    return {
      users, toast,
      edituser, deleteuser,
      columnDefs: [

        { headerName: "Name", field: "name" },
        { headerName: "Username", field: "username" },
        {
          headerName: "Role",
          field: "roles",
          cellRenderer: function (params) {
            if (Object.keys(params.value).length != 0) {
              let vul = '';
              Object.keys(params.value).forEach(function (key) {
                vul = params.value[key].name;
              });
              return vul;
            }

          },
        },
        { headerName: "Status", field: "status" },
        {
          headerName: "Edit",
          field: 'edit',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-warning");
            input.innerHTML = "Edit";
            input.addEventListener("click", function (event) {
              edituser(params.data.id);
            });
            return input;
          },

        },
        {
          headerName: "Delete",
          field: 'delete',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-danger");
            input.innerHTML = "Delete";
            input.addEventListener("click", function (event) {
              deleteuser(params.data.id);
            });
            return input;
          },

        },
      ],

    }
  },

};


</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
