<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Product</h4>
          <p class="card-description">Create product</p>
          <VeeForm class="pt-3" @submit="storeProduct">
            <div class="form-group">
              <label for="exampleInputUsername1">Name</label>

              <VeeField
                name="productname"
                type="text"
                class="form-control form-control-lg"
                placeholder="product Name"
                v-model="product.productname"
                rules="required"
              />
              <ErrorMessage name="productname" />
            </div>

            <div class="form-group">
              <label for="exampleInputUsername1">Sort Order</label>

              <VeeField
                name="sort"
                type="number"
                step="1"
                class="form-control form-control-lg"
                placeholder="Sort Order"
                v-model="product.sort"
                rules="required"
              />
              <ErrorMessage name="sort" />
            </div>

            <div class="form-group">
              <label for="exampleInputUsername1">Category</label>

              <v-select
                label="name"
                :options="categories"
                :reduce="(name) => name.id"
                v-model="product.category"
                multiple
              ></v-select>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Attribute Type</label>
              <v-select
                label="name"
                :options="attributetypes"
                v-model="product.attributetype"
              />
            </div>
            <div class="form-group" v-if="product.attributetype == 'febrics'">
              <label for="exampleInputUsername1">Attributes</label>

              <v-select
                label="name"
                :options="attributes"
                :reduce="(name) => name.id"
                v-model="product.attribute"
                multiple
              ></v-select>
            </div>

            <div
              class="col-md-9 grid-margin stretch-card"
              v-if="product.attributetype == 'others'"
            >
              <div class="card">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col">
                      <label>Attribute</label>
                      <div id="attributes">
                        <v-select
                          label="name"
                          :options="attributes"
                          :reduce="(name) => name.id + '_' + name.name"
                          v-model="custattribute.attribute"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col">
                      <label>Price</label>
                      <div id="price">
                        <input
                          class="price"
                          v-model="custattribute.price"
                          type="number"
                          min="0"
                          step="0.01"
                          placeholder="Price"
                        />
                      </div>
                    </div>
                    <div class="col" style="padding-top: 25px">
                      <a
                        class="btn btn-sm btn-primary"
                        @click="populateattributearr"
                      >
                        <i class="mdi mdi-plus"></i>
                      </a>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Attribute</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(custattb, cind) in product.otherattribute"
                          :key="cind"
                        >
                          <td>{{ custattb.attributename }}</td>
                          <td>{{ custattb.price }}</td>
                          <td>
                            <a @click="removeattributearr(cind)"
                              ><label class="badge badge-danger"
                                >Remove</label
                              ></a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Short Details</label>
              <div v-if="editorshort">
                <button
                  @click="editorshort.chain().focus().toggleBold().run()"
                  :class="{ 'is-active': editorshort.isActive('bold') }"
                >
                  bold
                </button>
                <button
                  @click="editorshort.chain().focus().toggleItalic().run()"
                  :class="{ 'is-active': editorshort.isActive('italic') }"
                >
                  italic
                </button>
                <button
                  @click="editorshort.chain().focus().toggleStrike().run()"
                  :class="{ 'is-active': editorshort.isActive('strike') }"
                >
                  strike
                </button>

                <button
                  @click="editorshort.chain().focus().unsetAllMarks().run()"
                >
                  clear marks
                </button>
                <button @click="editorshort.chain().focus().clearNodes().run()">
                  clear nodes
                </button>
                <button
                  @click="editorshort.chain().focus().setParagraph().run()"
                  :class="{ 'is-active': editorshort.isActive('paragraph') }"
                >
                  paragraph
                </button>
                <button
                  @click="
                    editorshort
                      .chain()
                      .focus()
                      .toggleHeading({ level: 1 })
                      .run()
                  "
                  :class="{
                    'is-active': editorshort.isActive('heading', { level: 1 }),
                  }"
                >
                  h1
                </button>
                <button
                  @click="
                    editorshort
                      .chain()
                      .focus()
                      .toggleHeading({ level: 2 })
                      .run()
                  "
                  :class="{
                    'is-active': editorshort.isActive('heading', { level: 2 }),
                  }"
                >
                  h2
                </button>
                <button
                  @click="
                    editorshort
                      .chain()
                      .focus()
                      .toggleHeading({ level: 3 })
                      .run()
                  "
                  :class="{
                    'is-active': editorshort.isActive('heading', { level: 3 }),
                  }"
                >
                  h3
                </button>
                <button
                  @click="
                    editorshort
                      .chain()
                      .focus()
                      .toggleHeading({ level: 4 })
                      .run()
                  "
                  :class="{
                    'is-active': editorshort.isActive('heading', { level: 4 }),
                  }"
                >
                  h4
                </button>
                <button
                  @click="
                    editorshort
                      .chain()
                      .focus()
                      .toggleHeading({ level: 5 })
                      .run()
                  "
                  :class="{
                    'is-active': editorshort.isActive('heading', { level: 5 }),
                  }"
                >
                  h5
                </button>
                <button
                  @click="
                    editorshort
                      .chain()
                      .focus()
                      .toggleHeading({ level: 6 })
                      .run()
                  "
                  :class="{
                    'is-active': editorshort.isActive('heading', { level: 6 }),
                  }"
                >
                  h6
                </button>
                <button
                  @click="editorshort.chain().focus().toggleBulletList().run()"
                  :class="{ 'is-active': editorshort.isActive('bulletList') }"
                >
                  bullet list
                </button>
                <button
                  @click="editorshort.chain().focus().toggleOrderedList().run()"
                  :class="{ 'is-active': editorshort.isActive('orderedList') }"
                >
                  ordered list
                </button>
                <button
                  @click="editorshort.chain().focus().toggleBlockquote().run()"
                  :class="{ 'is-active': editorshort.isActive('blockquote') }"
                >
                  blockquote
                </button>
                <button
                  @click="editorshort.chain().focus().setHorizontalRule().run()"
                >
                  horizontal rule
                </button>
                <button
                  @click="editorshort.chain().focus().setHardBreak().run()"
                >
                  hard break
                </button>
                <button @click="editorshort.chain().focus().undo().run()">
                  undo
                </button>
                <button @click="editorshort.chain().focus().redo().run()">
                  redo
                </button>
              </div>
              <editor-content :editor="editorshort" />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Long Details</label>
              <div v-if="editorlong">
                <button
                  @click="editorlong.chain().focus().toggleBold().run()"
                  :class="{ 'is-active': editorlong.isActive('bold') }"
                >
                  bold
                </button>
                <button
                  @click="editorlong.chain().focus().toggleItalic().run()"
                  :class="{ 'is-active': editorlong.isActive('italic') }"
                >
                  italic
                </button>
                <button
                  @click="editorlong.chain().focus().toggleStrike().run()"
                  :class="{ 'is-active': editorlong.isActive('strike') }"
                >
                  strike
                </button>

                <button
                  @click="editorlong.chain().focus().unsetAllMarks().run()"
                >
                  clear marks
                </button>
                <button @click="editorlong.chain().focus().clearNodes().run()">
                  clear nodes
                </button>
                <button
                  @click="editorlong.chain().focus().setParagraph().run()"
                  :class="{ 'is-active': editorlong.isActive('paragraph') }"
                >
                  paragraph
                </button>
                <button
                  @click="
                    editorlong.chain().focus().toggleHeading({ level: 1 }).run()
                  "
                  :class="{
                    'is-active': editorlong.isActive('heading', { level: 1 }),
                  }"
                >
                  h1
                </button>
                <button
                  @click="
                    editorlong.chain().focus().toggleHeading({ level: 2 }).run()
                  "
                  :class="{
                    'is-active': editorlong.isActive('heading', { level: 2 }),
                  }"
                >
                  h2
                </button>
                <button
                  @click="
                    editorlong.chain().focus().toggleHeading({ level: 3 }).run()
                  "
                  :class="{
                    'is-active': editorlong.isActive('heading', { level: 3 }),
                  }"
                >
                  h3
                </button>
                <button
                  @click="
                    editorlong.chain().focus().toggleHeading({ level: 4 }).run()
                  "
                  :class="{
                    'is-active': editorlong.isActive('heading', { level: 4 }),
                  }"
                >
                  h4
                </button>
                <button
                  @click="
                    editorlong.chain().focus().toggleHeading({ level: 5 }).run()
                  "
                  :class="{
                    'is-active': editorlong.isActive('heading', { level: 5 }),
                  }"
                >
                  h5
                </button>
                <button
                  @click="
                    editorlong.chain().focus().toggleHeading({ level: 6 }).run()
                  "
                  :class="{
                    'is-active': editorlong.isActive('heading', { level: 6 }),
                  }"
                >
                  h6
                </button>
                <button
                  @click="editorlong.chain().focus().toggleBulletList().run()"
                  :class="{ 'is-active': editorlong.isActive('bulletList') }"
                >
                  bullet list
                </button>
                <button
                  @click="editorlong.chain().focus().toggleOrderedList().run()"
                  :class="{ 'is-active': editorlong.isActive('orderedList') }"
                >
                  ordered list
                </button>
                <button
                  @click="editorlong.chain().focus().toggleBlockquote().run()"
                  :class="{ 'is-active': editorlong.isActive('blockquote') }"
                >
                  blockquote
                </button>
                <button
                  @click="editorlong.chain().focus().setHorizontalRule().run()"
                >
                  horizontal rule
                </button>
                <button
                  @click="editorlong.chain().focus().setHardBreak().run()"
                >
                  hard break
                </button>
                <button @click="editorlong.chain().focus().undo().run()">
                  undo
                </button>
                <button @click="editorlong.chain().focus().redo().run()">
                  redo
                </button>
              </div>
              <editor-content :editor="editorlong" />
            </div>

            <div class="form-group">
              <UploadMedia
                :error="imgerror"
                :server="imgserver"
                @media="ImgMedia"
              />
            </div>

            <div class="form-group">
              <label for="exampleInputUsername1">Base Price</label>

              <VeeField
                name="baseprice"
                type="number"
                step="0.01"
                class="form-control form-control-lg"
                placeholder="Base Price"
                v-model="product.baseprice"
                rules="required"
              />
              <ErrorMessage name="baseprice" />
            </div>

            <div class="form-group">
              <label for="exampleInputUsername1">Selling Price</label>

              <VeeField
                name="sellingprice"
                type="number"
                step="0.01"
                class="form-control form-control-lg"
                placeholder="Selling Price"
                v-model="product.sellingprice"
                rules="required"
              />
              <ErrorMessage name="sellingprice" />
            </div>
            <div class="form-group">
              <label for="exampleInputUsername1">Minimum Order</label>

              <VeeField
                name="order"
                type="number"
                step="0.01"
                class="form-control form-control-lg"
                placeholder="Minimum Order Qn"
                v-model="product.minimumorder"
                rules="required"
              />
              <ErrorMessage name="order" />
            </div>

            <div class="form-group">
              <label for="exampleInputUsername1">Stock</label>

              <VeeField
                name="stock"
                type="number"
                class="form-control form-control-lg"
                placeholder="Current stock"
                v-model="product.productstock"
                rules="required"
              />
              <ErrorMessage name="stock" />
            </div>

            <div class="form-group">
              <label for="exampleInputUsername1">Default Size</label>
              <VeeField
                name="defaultsize"
                type="text"
                class="form-control form-control-lg"
                placeholder="product Name"
                v-model="product.defaultsize"
                rules="required"
              />
              <ErrorMessage name="defaultsize" />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">Unit</label>
              <v-select
                label="name"
                :options="units"
                v-model="product.unit"
              ></v-select>
            </div>
            <div class="form-group">
              <label for="exampleInputUsername1">Has custom price?</label>

              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="product.customprice" />
                  yes
                  <i class="input-helper"></i
                ></label>
              </div>
            </div>

            <div class="form-group" v-if="product.customprice == true">
              <label for="exampleInputUsername1">Formula</label>

              <v-select
                label="name"
                :options="formulas"
                :reduce="(name) => name.id"
                v-model="product.formula"
              ></v-select>
            </div>

            <div class="form-group" v-if="product.customprice == true">
              <label for="exampleInputEmail1">Heights: </label>
              <v-select
                label="custom_height"
                :options="customheights"
                v-model="product.customheight"
                multiple
              ></v-select>
            </div>

            <div class="form-group">
              <label for="exampleInputUsername1">Includes Shipping Charge?</label>

              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="product.hasshippingcharge" />
                  Active 
                  <i class="input-helper"></i
                ></label>
              </div>
            </div>

            <div class="form-group" v-if="product.hasshippingcharge == true">
              <label for="exampleInputUsername1">Inside Dhaka</label>

              <VeeField
                name="dhaka"
                type="number"
                step="0.01"
                class="form-control form-control-lg"
                placeholder="Inside Dhaka"
                v-model="product.insidedhaka"
                rules="required"
              />
              <ErrorMessage name="dhaka" />
            </div>

            <div class="form-group" v-if="product.hasshippingcharge == true">
              <label for="exampleInputUsername1">Inside Dhaka</label>

              <VeeField
                name="outside"
                type="number"
                step="0.01"
                class="form-control form-control-lg"
                placeholder="Outside Dhaka"
                v-model="product.outsidedhaka"
                rules="required"
              />
              <ErrorMessage name="outside" />
            </div>

            <div class="form-group">
              <label for="exampleInputUsername1">Status</label>

              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="product.status" />
                  Active 
                  <i class="input-helper"></i
                ></label>
              </div>
            </div>

            <button type="submit" class="btn btn-primary me-2">Submit</button>
            <button class="btn btn-light">Cancel</button>
          </VeeForm>
        </div>
      </div>
    </div>
  </div>
  <!-- content-wrapper ends -->
</template>


<script>
import { watchEffect, ref, onMounted, reactive, getCurrentInstance } from "vue";
import { useToast } from "vue-toastification";
import { UploadMedia, UpdateMedia } from "vue-media-upload";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";

export default {
  name: "CreateProduct",
  components: {
    UploadMedia,
    UpdateMedia,
    EditorContent,
  },
  setup() {
    const toast = useToast();
    const emit = getCurrentInstance();

    const errors = ref([]);
    const categories = ref([]);
    const attributetypes = ref(["none", "febrics", "others"]);
    const attributes = ref([]);
    const units = ref(["Pcs", "L", "Kg"]);
    const customheights = ref([
      "0.5",
      "1",
      "1.5",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
    ]);
    const formulas = ref([
      {
        id: "sft",
        name: "(LxWxH)/144 (SFT)",
      },
      {
        id: "cft",
        name: "(LxWxH)/1728 (CFT)",
      },
    ]);
    const product = reactive({
      productsku: "",
      productname: "",
      category: [],
      attributetype: "",
      attribute: [],
      otherattribute: [],
      productsdetails: "",
      productdetails: "",
      baseprice: "",
      sellingprice: "",
      defaultsize: "",
      unit: "",
      productstock: 1,
      minimumorder: "",
      customprice: "",
      formula: "",
      customheight: [],
      productmedia: [],
      sort: 0,
      hasshippingcharge: false,
      status: 0,
      insidedhaka: 0,
      outsidedhaka: 0
    });

    const editorshort = useEditor({
      extensions: [StarterKit],
      content: "",
      onUpdate: ({ editor }) => {
        // console.log("Updated", editor.getText(), editor.getHTML());
        product.productsdetails = editor.getHTML();
      },
    });
    const editorlong = useEditor({
      extensions: [StarterKit],
      content: "",
      onUpdate: ({ editor }) => {
        // console.log("Updated", editor.getText(), editor.getHTML());
        product.productdetails = editor.getHTML();
      },
    });

    const custattribute = reactive({
      attribute: "",
      attributeid: "",
      attributename: "",
      price: 0,
    });

    const getcategories = async () => {
      let res = await axios.get("/admin/get-all-categories");
      categories.value = res.data;
    };

    watchEffect(() => getattributes(product.attributetype));

    function getattributes(value) {
      if (value) {
        axios.get("/admin/get-type-attributes/" + value).then((response) => {
          product.attribute = null;
          attributes.value = response.data;
        });
      }
    }

    const populateattributearr = async () => {
      let attrbdet = custattribute.attribute.toString().split("_");
      custattribute.attributeid = attrbdet[0];
      custattribute.attributename = attrbdet[1];

      let el = product.otherattribute.find(
        (e) => e.attributeid === custattribute.attributeid
      );

      if (el) {
        Object.assign(el, custattribute);
      } else {
        product.otherattribute.push({
          attributeid: custattribute.attributeid,
          attributename: custattribute.attributename,
          price: custattribute.price,
        });
      }

      // custattributereset();
      // console.log(product.otherattribute);
    };

    function removeattributearr(key) {
      product.otherattribute.splice(key, 1);
    }

    const custattributereset = () => {
      custattribute.attribute = "";
      custattribute.attributeid = "";
      custattribute.attributename = "";
      custattribute.price = "";
    };

    const storeProduct = async () => {
      // console.log(product);
      try {
        const res = await axios.post("/admin/store-product", product);
        toast.success("Product created successfully!", {
          timeout: 2000,
        });
        // console.log(res)
        window.location.href = "/admin/products";
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    };

    const ImgMedia = async (value) => {
      product.productmedia = value;
    };

    const formReset = () => {
      product.productsku = "";
      product.productname = "";
      product.category = "";
      product.productsdetails = "";
      product.productdetails = "";
      product.baseprice = "";
      product.sellingprice = "";
      product.unit = "";
      product.productstock = "";
      product.customprice = false;
      product.productmedia = [];
    };

    onMounted(getcategories());

    return {
      product,
      attributes,
      attributetypes,
      custattribute,
      populateattributearr,
      removeattributearr,
      categories,
      units,
      customheights,
      formulas,
      editorshort,
      editorlong,
      storeProduct,
      errors,
      toast,
      ImgMedia,
      imgerror: "",
      imgserver: "/admin/uploadimage",
    };
  },
};
</script>


<style lang="scss">
@import "../../../../../node_modules/vue-good-table-next/src/styles/style.scss";
@import "../../../../../node_modules/vue-toastification/dist/index.css";
</style>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  height: 200px;
  background: #d7d7d7;
  overflow: scroll;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
