<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Products</h4>
          <p class="card-description">Product <code>Products</code></p>
          <div class="table-responsive">
            <ag-grid-vue
              style="width: 100%; height: 500px"
              class="ag-theme-alpine"
              :columnDefs="columnDefs"
              :rowData="products"
              :pagination="true"
            >
            </ag-grid-vue>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- content-wrapper ends -->
</template>

<script>
import { ref, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { AgGridVue } from "ag-grid-vue3";
import { useCookies } from "vue3-cookies";

export default {
  name: "Products",
  components: {
    AgGridVue,
  },

  setup() {
    const products = ref([]);
    const toast = useToast();
    const { cookies } = useCookies();

    const getproducts = async () => {
      let res = await axios.get("/admin/get-all-products");
      products.value = res.data;
    };

    const editproduct = async (value) => {
      cookies.set("prodv", value);
      window.location.href = "/admin/edit-product";
    };

    const deleteproduct = async (id) => {
      await axios.delete("/admin/delete-product/" + id);
      getproducts();
      toast.success("product deleted successfully!", {
        timeout: 2000,
      });
    };

    onMounted(getproducts());

    return {
      products,
      toast,
      editproduct,
      deleteproduct,
      columnDefs: [
        {
          headerName: "Image",
          field: "img",
          cellRenderer: "imgCellRenderer",
          cellRenderer: function (params) {
            var img = document.createElement("img");
            img.src =
              "../../../../../uploads/products/" + params.data.featured_img;
            img.setAttribute("class", "img img-responsive");
            img.setAttribute("width", "50%");
            return img;
          },
          width: 100
        },
        { headerName: "Name", field: "name" },
        { headerName: "Def. Size", field: "default_size" },
        { headerName: "Order", field: "sort" },
        { headerName: "Shipping", field: "has_shipping_charge" },
        {
          headerName: "Edit",
          field: "edit",
          cellRenderer: "btnCellRenderer",
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-warning");
            input.innerHTML = "Edit";
            input.addEventListener("click", function (event) {
              editproduct(params.data.id);
            });
            return input;
          },
          width: 90
        },
        {
          headerName: "Delete",
          field: 'delete',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-danger");
            input.innerHTML = "Delete";
            input.addEventListener("click", function (event) {
              deleteproduct(params.data.id);
            });
            return input;
          },

        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
