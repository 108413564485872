<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">

      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Banners</h4>
          <p class="card-description">
            Site <code>Banner</code>
          </p>
          <div class="table-responsive">
            <ag-grid-vue style="width: 100%; height: 500px;" class="ag-theme-alpine" :columnDefs="columnDefs"
              :rowData="banners" :pagination="true">
            </ag-grid-vue>

          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- content-wrapper ends -->
</template>

<script>
import { ref, onMounted } from 'vue';
import { useToast } from "vue-toastification";
import { AgGridVue } from "ag-grid-vue3";

export default {
  name: "Banners",
  components: {
    AgGridVue,
  },

  setup() {
    const banners = ref([]);
    const toast = useToast();
    const getbanners = async () => {
      let res = await axios.get("/admin/get-all-banners");
      banners.value = res.data;
    }

    const editbanner = async (value) => {
      console.log('this is edit' + value);
    }

    const deletebanner = async (id) => {
      await axios.delete("/admin/delete-banner/" + id);
      getbanners();
      toast.success("Banner deleted successfully!", {
        timeout: 2000
      });
    }

    onMounted(getbanners());

    return {
      banners, toast,
      editbanner, deletebanner,
      columnDefs: [
        {
          headerName: "Image",
          field: 'img',
          cellRenderer: 'imgCellRenderer',
          cellRenderer: function (params) {
            var img = document.createElement("img");
            img.src = "../../../../../uploads/banners/" + params.data.banner_img;
            img.setAttribute("class", "img img-responsive");
            img.setAttribute("width", "20%");
            return img;
          },
        },
        { headerName: "Title", field: "title" },
        { headerName: "Subtitle", field: "subtitle" },
        { headerName: "URL", field: "url" },
        { headerName: "Order", field: "order" },
        { headerName: "Status", field: "status" },
        {
          headerName: "Edit",
          field: 'edit',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-warning");
            input.innerHTML = "Edit";
            input.addEventListener("click", function (event) {
              editbanner(params.data.id);
            });
            return input;
          },
        },
        {
          headerName: "Delete",
          field: 'delete',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-danger");
            input.innerHTML = "Delete";
            input.addEventListener("click", function (event) {
              deletebanner(params.data.id);
            });
            return input;
          },
        },
      ],

    }
  },

};


</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
