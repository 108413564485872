<template>
  <!-- main content -->
  <div class="main-content">
    <div id="wrapper-site">
      <div id="content-wrapper">
        <div id="main">
          <div class="page-home">
            <!-- breadcrumb -->
            <nav class="breadcrumb-bg">
              <div class="container no-index">
                <div class="breadcrumb">
                  <ol>
                    <li>
                      <a :href="'/'">
                        <span>Home</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>Terms & Conditions</span>
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
            </nav>
            <div class="container">
              <div class="content">
                <div class="row">
                  <div
                    class="col-sm-8 col-lg-9 col-md-9 flex-xs-first main-blogs"
                  >
                    <h3>Terms & Conditions</h3>

                    <div class="late-item">
                      <p class="content-title">OVERVIEW</p>
                      <p>
                        This is official website of SWAN GROUP which is one of
                        the most popular FOAM & MATTRESS manufacturer company in
                        Bangladesh. In our website <a href="www.swan.com.bd">www.swan.com.bd</a> we offer our
                        all products for valuable customers. The customer shall
                        be deemed to have accepted all the terms, conditions,
                        policies and notices officially stated, as they continue
                        to use the website and its services.
                      </p>
                      <p>
                        By visiting our website and/or purchasing something from
                        us, you become a user of the website and engage in our
                        “Services”. Such engagement will consecutively mean the
                        user agreeing to be bound by the following terms and
                        conditions (also referred to as “Terms of Service”,
                        including those additional terms & conditions and
                        policies referenced herein and/or available by
                        hyperlink. These Terms of Service apply to all users of
                        the website including, but not limited to, users who are
                        browsers, vendors, customers, merchants, affiliations
                        and/ or contributors of content.
                      </p>

                      <p>
                        Please read this Terms of Service carefully before
                        accessing or using our website or our services. By
                        accessing or using any part of the website, you agree to
                        be legally bound by these Terms of Service. If you do
                        not agree to all the Terms of this agreement, then you
                        may not access the website or use any of the services.
                      </p>

                      <p>
                        Any new addition of features, service or tools shall
                        also be deemed to be subject to the Terms of Service in
                        force. You can review the most current version of the
                        Terms of Service at any time on this page. We reserve
                        the right to update, change or replace any part of these
                        Terms & Conditions by posting updates and/or changes to
                        our website. Therefore in order to stay updated on the
                        Terms, please check this page periodically for changes.
                        Your continued use of, or access to the website,
                        following the posting of any changes constitutes
                        acceptance of those changes.
                      </p>
                      <p>
                        <strong>a)</strong> Our available products are shown in the website with
                        price. If the product you have ordered are not
                        available, our customer care service will inform you by
                        call or email. Our Customer Care will offer similar
                        alternative products to you or the option to cancel your
                        order wholly.
                      </p>
                      <p>
                        <strong>b)</strong> All prices are subject to change without
                        notification, and while every effort is made to ensure
                        the accuracy of the prices displayed on swan.com.bd they
                        are not guaranteed to be accurate. If any price is
                        different from that displayed we will inform you before
                        dispatching the order and you will have the option of
                        continuing with the order or not.
                      </p>
                      <p>
                        <strong>c)</strong> We reserve the right at any time to modify or
                        discontinue any product without notice at any time.
                      </p>
                      <p>
                        <strong>d)</strong> Delivery Time: Inside Dhaka – 2-3 days & Outside
                        Dhaka – 3-5 days).
                      </p>
                      <p>
                        <strong>e)</strong> Choosing the right payment method is essential in
                        order to shop with our website. SWAN is accepting
                        payments in very easy and convenient ways. The chart of
                        the payment methods of swan.com.bd is given below:
                        <ol>
                            <li> &nbsp;&nbsp;&nbsp;   • Online Payment: Applicable for any ordered amount as per
                        company policy.</li>
                            <li> &nbsp;&nbsp;&nbsp; • bKash Payment: Applicable for any
                        ordered amount as per company policy. (Merchant No:
                        01730060199)</li>
                            <li> &nbsp;&nbsp;&nbsp; • Cash on Delivery: Dhaka city customer
                        will get cash on delivery service.</li>
                        </ol>
                      </p>
                      <p>
                        <strong>f)</strong> For more information regarding the Terms of Service or if you have any queries or concerns you can <strong>Help center</strong> or for any questions about the Terms of Service should be sent to us at <a href="mailto:marketing@swan-family.com">marketing@swan-family.com</a> 
                        </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>