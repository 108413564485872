<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Category</h4>
          <p class="card-description">Create/update Category</p>
          <VeeForm class="pt-3" @submit="updatecategory">
            <div class="form-group">
              <label for="exampleInputUsername1">Name</label>

              <VeeField
                name="categoryname"
                type="text"
                class="form-control form-control-lg"
                placeholder="Category Name"
                v-model="category.categoryname"
                rules="required"
              />
              <ErrorMessage name="categoryname" />
            </div>
            <div class="form-group">
              <label for="exampleInputUsername1">Sort Order</label>

              <VeeField
                name="sort"
                type="number"
                step="1"
                class="form-control form-control-lg"
                placeholder="Sort Order"
                v-model="category.sort"
                rules="required"
              />
              <ErrorMessage name="sort" />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Details</label>
              <VeeField
                name="categorydetails"
                type="text"
                class="form-control form-control-lg"
                placeholder="Category Details"
                v-model="category.categorydetails"
                rules="required"
              />
              <ErrorMessage name="categorydetails" />
            </div>
            <div class="form-group">
              <span>
                <img
                  :src="
                    '../../../../../uploads/categories/' + category.featureding
                  "
                  width="100"
                />
              </span>
              <UploadMedia
                :error="imgerror"
                :server="imgserver"
                @media="ImgMedia"
              />
            </div>

            <div class="form-group">
              <label for="exampleInputUsername1">Status</label>

              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="category.status" />
                  Active 
                  <i class="input-helper"></i
                ></label>
              </div>
            </div>

            <button type="submit" class="btn btn-primary me-2">Submit</button>
            <button class="btn btn-light">Cancel</button>
          </VeeForm>
        </div>
      </div>
    </div>
  </div>
  <!-- content-wrapper ends -->
</template>


<script>
import { ref, onMounted, reactive } from "vue";
import { useToast } from "vue-toastification";
import { UploadMedia, UpdateMedia } from "vue-media-upload";
import { useCookies } from "vue3-cookies";

export default {
  name: "CreateCategories",
  components: {
    UploadMedia,
    UpdateMedia,
  },
  props: {
    msg: String,
  },
  setup() {
    const toast = useToast();
    const errors = ref([]);
    const { cookies } = useCookies();

    const category = reactive({
      categoryid: cookies.get("catv"),
      categoryname: "",
      categorydetails: "",
      featureding: "",
      categorymedia: [],
      sort: 0
    });

    const getcategory = async () => {
      let res = await axios.get("/admin/get-category/" + category.categoryid);
      category.categoryname = res.data.name;
      category.categorydetails = res.data.details;
      category.featureding = res.data.featured_img;
      category.status = res.data.status != 1 ? false : true;
      category.sort = res.data.sort;
    };

    const updatecategory = async () => {
      try {
        const res = await axios.post("/admin/update-category", category);
        toast.success("Category created successfully!", {
          timeout: 2000,
        });
        // console.log(res)
        getcategory();
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    };

    const ImgMedia = async (value) => {
      category.categorymedia = value;
      // console.log(category.categorymedia)
    };

    const formReset = () => {
      category.categoryname = "";
      category.categorydetails = "";
    };

    onMounted(getcategory());

    return {
      category, updatecategory,
      errors,
      toast,
      ImgMedia,
      imgerror: "",
      imgserver: "/admin/uploadimage",
    };
  },
};
</script>


<style lang="scss">
@import "../../../../../node_modules/vue-good-table-next/src/styles/style.scss";
@import "../../../../../node_modules/vue-toastification/dist/index.css";
</style>
