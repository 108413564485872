<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">

      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Roles</h4>
          <p class="card-description">
            Admin <code>Roles</code>
          </p>
          <div class="table-responsive">
            <ag-grid-vue style="width: 100%; height: 500px;" class="ag-theme-alpine" :columnDefs="columnDefs"
              :rowData="roles" :pagination="true">
            </ag-grid-vue>

          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- content-wrapper ends -->
</template>

<script>
import { ref, onMounted } from 'vue';
import { useToast } from "vue-toastification";
import { AgGridVue } from "ag-grid-vue3";

export default {
  name: "Roles",
  components: {
    AgGridVue,
  },

  setup() {
    const roles = ref([]);
    const toast = useToast();
    const getroles = async () => {
      let res = await axios.get("/admin/get-all-roles");
      roles.value = res.data;
    }

    const editrole = async (value) => {
      console.log('this is edit' + value);
    }

    const deleterole = async (id) => {
      await axios.delete("/admin/delete-role/" + id);
      getroles();
      toast.success("role deleted successfully!", {
        timeout: 2000
      });
    }

    onMounted(getroles());

    return {
      roles, toast,
      editrole, deleterole,
      columnDefs: [
        
        { headerName: "Name", field: "name" },
        
        {
          headerName: "Edit",
          field: 'edit',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-warning");
            input.innerHTML = "Edit";
            input.addEventListener("click", function (event) {
              editrole(params.data.id);
            });
            return input;
          },

        },
        {
          headerName: "Delete",
          field: 'delete',
          cellRenderer: 'btnCellRenderer',
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-danger");
            input.innerHTML = "Delete";
            input.addEventListener("click", function (event) {
              deleterole(params.data.id);
            });
            return input;
          },

        },
      ],

    }
  },

};


</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
