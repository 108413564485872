<template>
  <div class="content-wrapper d-flex align-items-center auth px-0">
    <div class="row w-100 mx-0">
      <div class="col-lg-4 mx-auto">
          Hello! I am in.
      </div>
    </div>
  </div>
  <!-- content-wrapper ends -->
</template>
<script>
import { useToast } from "vue-toastification";
export default {
  name: "Dashboard",
  props: {
    msg: String,
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      count: 0,
      logo: "../assets/img/logo_200.png",
      login: {
        username: "",
        password: "",
      },
    };
  },
  methods: {

  },
};
</script>
<style scoped>
.auth .brand-logo {
  text-align: center;
}
</style>