<template>
    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">

            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Roles</h4>
                    <p class="card-description">
                        Create/update Role
                    </p>
                    <VeeForm class="pt-3" @submit="storerole">
                        <div class="form-group">
                            <label for="exampleInputUsername1">Name</label>

                            <VeeField name="rolename" type="text" class="form-control form-control-lg"
                                placeholder="Role Name" v-model="roles.rolename" rules="required" />
                            <ErrorMessage name="rolename" />
                        </div>

                        <button type="submit" class="btn btn-primary me-2">Submit</button>
                        <button class="btn btn-light">Cancel</button>
                    </VeeForm>
                </div>
            </div>

        </div>
    </div>
    <!-- content-wrapper ends -->
</template>


<script>
import { ref, onMounted, reactive } from 'vue';
import { useToast } from "vue-toastification";

export default {
    name: "CreateRoles",

    props: {
        msg: String,
    },
    setup() {
        const toast = useToast();
        const errors = ref([]);
        const roles = reactive({
            rolename: '',
        });

        const storerole = async () => {
            try {
                const res = await axios.post("/admin/store-role", roles)
                toast.success("Created successfully!", {
                    timeout: 2000
                });
                // console.log(res)
                window.location.href = '/admin/roles';
                // formReset();
            } catch (e) {
                if (e.response.status == 422) {
                    var data = [];
                    for (const key in e.response.data.errors) {
                        data.push(e.response.data.errors[key][0]);
                    }
                    errors.value = data;
                }
            }
        }

        const formReset = () => {
            roles.rolename = '';
        }

        return {
            roles, storerole, errors, toast,
        }
    },
};
</script>


<style lang="scss">
@import "../../../../../node_modules/vue-good-table-next/src/styles/style.scss";
@import "../../../../../node_modules/vue-toastification/dist/index.css";
</style>
