<template>
  <div id="checkout" class="main-content">
    <div class="wrap-banner">
      <!-- breadcrumb -->
      <nav class="breadcrumb-bg">
        <div class="container no-index">
          <div class="breadcrumb">
            <ol>
              <li>
                <a :href="'/'">
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>Checkout</span>
                </a>
              </li>
            </ol>
          </div>
        </div>
      </nav>

      <!-- main -->
      <div id="wrapper-site">
        <div class="container">
          <div class="row">
            <div id="content-wrapper" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 onecol">
              <div id="main">
                <div class="cart-grid row">
                  <div class="col-md-9 check-info">
                    <div class="checkout-personal-step">
                      <h3 class="step-title h3 info">
                        <span class="step-number">1</span>PERSONAL INFORMATION
                      </h3>
                    </div>

                    <div class="content" v-if="loggeduser.status == 0">
                      <ul class="nav nav-inline">
                        <li class="nav-item">
                          <a class="nav-link active" data-toggle="tab" href="#checkout-guest-form">
                            Register a new account
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" data-toggle="tab" href="#checkout-login-form">
                            SIGN IN
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div class="tab-pane fade in active show" id="checkout-guest-form" role="tabpanel"
                          v-if="!otp.checkotp">
                          <VeeForm class="js-customer-form" id="customer-form" @submit="newRegister">
                            <div>
                              <div class="desc-password">
                                <span class="font-weight-bold">Create an account</span>
                                <span>(optional)</span>
                                <br />
                                <span class="text-muted">And save time on your next order!</span>
                              </div>
                              <div class="form-group">
                                <VeeField name="fullname" type="text" class="form-control" placeholder="Full name"
                                  v-model="register.fullname" rules="required" />
                                <ErrorMessage name="fullname" />
                              </div>
                              <div class="input-group mb-4 mr-sm-2">
                                <div class="input-group-prepend">
                                  <div class="input-group-text" style="height: 100%">
                                    88
                                  </div>
                                </div>

                                <VeeField name="mobileno" type="number" class="form-control form-control-lg"
                                  placeholder="Mobile No" v-model="register.mobileno" :rules="validatePhone" /><br>
                                <ErrorMessage name="mobileno" />
                              </div>

                              <div class="form-group">
                                <VeeField name="email" type="text" class="form-control form-control-lg"
                                  placeholder="email (optional)" v-model="register.email" rules="" />
                                <ErrorMessage name="email" />
                              </div>
                              <div class="form-group">
                                <VeeField name="password" type="password" class="form-control form-control-lg"
                                  v-model="register.password" placeholder="Password" rules="required|min:4"
                                  id="password" />
                                <ErrorMessage name="password" />
                              </div>
                              <div class="form-group">
                                <VeeField name="password_confirmation" type="password"
                                  class="form-control form-control-lg" v-model="register.password_confirmation"
                                  placeholder="Re-password" :rules="validatePassword" />
                                <ErrorMessage name="password_confirmation" />
                              </div>
                            </div>
                            <div class="clearfix">
                              <div class="row">
                                <button class="continue btn btn-primary pull-xs-right" name="continue"
                                  data-link-action="register-new-customer" type="submit">
                                  Continue
                                </button>
                              </div>
                            </div>
                          </VeeForm>
                        </div>
                        <div class="tab-pane fade in active show" id="checkout-guest-form" v-if="otp.checkotp">
                          <div class="brand-logo">
                            <img v-bind:src="logo" />
                          </div>
                          <h4 class="fw-light">Please verify OTP</h4>
                          <!-- <h6 class="fw-light">Register for new account</h6> -->
                          <VeeForm class="pt-3" @submit="checkOTP">
                            <div class="form-group">
                              <VeeField name="otpcode" type="text" class="form-control form-control-lg"
                                placeholder="OTP" v-model="verifyotp.otpcode" rules="required|min:4" />
                              <ErrorMessage name="fullname" />
                            </div>

                            <div class="mt-3">
                              <button class="
                                  btn btn-block btn-primary btn-lg
                                  font-weight-medium
                                  auth-form-btn
                                " type="submit">
                                Verify OTP
                              </button>
                              <a :href="'/'" class="btn btn-light btn-lg">Cancel</a>
                            </div>
                          </VeeForm>
                        </div>
                        <div class="tab-pane fade" id="checkout-login-form" role="tabpanel">
                          <VeeForm class="customer-form" id="login-form" @submit="loginuser">
                            <div>
                              <div class="input-group mb-4 row">
                                <div class="input-group-prepend">
                                  <div class="input-group-text" style="height: 100%">
                                    88
                                  </div>
                                </div>
                                <VeeField name="username" type="number" class="form-control form-control-lg"
                                  placeholder="Username/Mobile no" v-model="login.username" rules="required|min:4" />
                                <ErrorMessage name="username" />
                              </div>

                              <div class="form-group row">
                                <div class="input-group js-parent-focus">
                                  <VeeField name="password" type="password" class="
                                      form-control
                                      js-child-focus js-visible-password
                                    " v-model="login.password" placeholder="Password" rules="required|min:4" />
                                  <ErrorMessage name="password" />
                                </div>
                              </div>

                              <div class="row">
                                <div class="forgot-password">
                                  <a href="#" rel="nofollow">
                                    Forgot your password?
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="clearfix">
                              <div class="row">
                                <button class="continue btn btn-primary pull-xs-right" name="continue"
                                  data-link-action="sign-in" type="submit" value="1">
                                  Continue
                                </button>
                              </div>
                            </div>
                          </VeeForm>
                        </div>
                      </div>
                    </div>
                    <div class="content" v-else>
                      <div class="form-group row">
                        <span class="font-weight-bold">Already logged in</span>
                      </div>
                    </div>
                    <div class="checkout-personal-step" v-if="loggeduser.status != 0">
                      <h3 class="step-title h3">
                        <span class="step-number">2</span>Addresses
                      </h3>
                    </div>
                    <div class="content" v-if="loggeduser.status != 0">
                      <VeeForm class="js-customer-form" id="customer-form" @submit="updateaddress">
                        <div>
                          <div class="form-group row">
                            <div class="input-group js-parent-focus">
                              <VeeField name="address" type="text" class="form-control" placeholder="Delivery address"
                                v-model="address.address" rules="required" />
                              <ErrorMessage name="address" />
                            </div>
                          </div>

                          <div class="form-group">
                            <v-select label="districts" :options="districts" v-model="address.district"></v-select>
                          </div>
                        </div>
                        <div class="clearfix">
                          <div class="row">
                            <button class="continue btn btn-primary pull-xs-right" name="update" type="submit">
                              Update
                            </button>
                          </div>
                        </div>
                      </VeeForm>
                    </div>

                    <div class="checkout-personal-step" v-if="
                      loggeduser.status != 0 && address.hasdistrict != false
                    ">
                      <h3 class="step-title h3">
                        <span class="step-number">3</span>Payment
                      </h3>
                    </div>
                    <div class="content" v-if="
                      loggeduser.status != 0 && address.hasdistrict != false
                    ">

                      <div class="form-group row check-input">
                        <span class="custom-checkbox d-inline-flex">
                          <input class="check" name="terms" type="checkbox" v-model="paymentd.terms" required />
                          <label class="label-absolute" style="padding: 7px 0px 0px 5px">
                            By checking this box, you are agreeing to our
                            <a :href="'/privacy-policy'" target="_blank">Privacy Policy</a>,
                            <a :href="'/terms-and-conditions'" target="_blank">Terms & Conditions</a>
                            and please read our
                            <a :href="'/return-and-refund-policy'" target="_blank">Refund Policy</a>.
                          </label>
                          <ErrorMessage name="terms" />
                        </span>
                      </div>
                      <div class="form-group row">
                        <!-- <button class="continue btn btn-primary pull-xs-left" name="payment" @click="paynow">
                          Pay now with SSLCommerze
                        </button> -->

                        <button class="btn btn-primary pull-xs-right" name="payoncash" @click="payoncash">
                          Cash on delivery
                        </button>
                      </div>
                      <!-- </VeeForm> -->
                    </div>
                  </div>
                  <div class="cart-grid-right col-xs-12 col-lg-3">
                    <div class="cart-summary">
                      <div class="cart-detailed-totals">
                        <div class="cart-summary-products">
                          <div class="summary-label">Cart Summary</div>
                        </div>
                        <div class="cart-summary-line" id="cart-subtotal-products">
                          <span class="label js-subtotal">
                            Total Items: &nbsp;
                          </span>
                          <span class="value">{{ cartdata.length }}</span>
                        </div>
                        <div v-if="coupon.couponactive" class="cart-summary-line" id="cart-subtotal-shipping">
                          <span class="label"> Coupon: &nbsp; </span>
                          <span class="value">{{ coupon.couponcode }}</span>
                          <div>
                            <small class="value"></small>
                          </div>
                        </div>
                        <div class="cart-summary-line" id="cart-subtotal-shipping">
                          <span class="label"> Total Shipping: &nbsp; </span>
                          <span class="value">৳ {{ carttotal.deliverycharge }}</span>
                          <div>
                            <small class="value"></small>
                          </div>
                        </div>
                        <div class="cart-summary-line cart-total">
                          <span class="label">Total: &nbsp;</span>
                          <span class="value">৳ {{ carttotal.totalprice }} (tax incl.)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { ref, onMounted, reactive, computed } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { useToast } from "vue-toastification";

export default {
  components: {
    Form,
    Field,
    ErrorMessage
  },
  setup() {
    // const route = useRoute();
    const toast = useToast();
    const cartdata = ref([]);
    const districts = ref(["Dhaka", "Others"]);
    const carttotal = reactive({
      totalprice: 0,
      deliverycharge: 0,
      deliverydetails: "",
    });

    const otp = reactive({
      checkotp: false
    });

    const generateotp = reactive({
      verify_phone_no: ""
    });

    const verifyotp = reactive({
      mobileno: "",
      otpcode: ""
    });

    const register = reactive({
      fullname: "",
      mobileno: "",
      email: "",
      password: "",
      password_confirmation: "",
    });

    const login = reactive({
      username: "",
      password: "",
    });

    const loggeduser = reactive({
      name: "",
      status: "",
    });

    const address = reactive({
      address: "",
      district: "",
      division: "",
      postcode: "",
      hasdistrict: false,
    });

    const coupon = reactive({
      couponcode: "",
      total: 0,
      couponactive: false,
    });

    const paymentd = reactive({
      terms: false,
    });

    const getloggeduser = async () => {
      let res = await axios.get("/checkloggeduser");

      if (res.data) {
        loggeduser.name = res.data.name;
        loggeduser.status = 1;
        address.address = res.data.address.address_1;
        address.district = res.data.address.district;
        if (res.data.address.district) {
          address.hasdistrict = true;
        }
        address.division = res.data.address.division;
        address.postcode = res.data.address.postcode;
      } else {
        loggeduser.status = 0;
      }
    };

    const validatePhone = async (value) => {
      if (!value) {
        return "Phone no is required";
      }
      if (value.length == 11) {
        let res = await axios.get("/checkphone/" + value);
        if (res.data) {
          // console.log("This number already is in use.");
          return "This number already is in use.";
        }
      }
      return true;
    };

    const validatePassword = async (value) => {
      if (!value || value.length < 4) {
        return 'This field is required';
      }
      const pass = document.getElementById('password').value;

      if (value !== pass) {
        return 'Password missmatch';
      }
      return true;
    };

    const newRegister = async () => {
      generateotp.verify_phone_no = register.mobileno;
      try {
        const res = await axios.post("/generate-otp", generateotp);
        console.log(res.data)
        if (res.data.status == 'success') {
          otp.checkotp = true;
          toast.success(res.data.message, {
            timeout: 2000,
          });
        } else {
          toast.warning(res.data.message, {
            timeout: 2000,
          });
        }

      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    };

    const checkOTP = async () => {
      verifyotp.mobileno = register.mobileno;
      try {
        const res = await axios.post("/verify-otp", verifyotp);
        console.log(res.data)
        if (res.data.status == "success") {
          try {
            const res = await axios.post("/register", register);
            toast.success("User registred successfully!", {
              timeout: 2000,
            });
            window.location.href = "checkout";
          } catch (e) {
            if (e.response.status == 422) {
              var data = [];
              for (const key in e.response.data.errors) {
                data.push(e.response.data.errors[key][0]);
              }
              errors.value = data;
            }
          }

        }

      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    }

    const loginuser = async () => {
      try {
        const res = await axios.post("/login", login);
        toast.success("User registred successfully!", {
          timeout: 2000,
        });
        window.location.href = "checkout";
      } catch (e) {
        if (e.response.status == 422) {
          toast.warning("Wrong username/password.", {
            timeout: 2000,
          });
        }
      }
    };

    const updateaddress = async () => {
      try {
        let res = await axios.post("/update-address", address);
        toast.success("Address updated successfully!", {
          timeout: 2000,
        });
        if (res.data.district) {
          address.hasdistrict = true;
        }
        getcartdata();
        // console.log(res.data);
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    };

    const getcoupondata = async () => {
      let res = await axios.get("/getsavedcoupon");
      if (res.data) {
        coupon.couponcode = res.data.couponcode[0];
        coupon.total = res.data.couponvalue[0];
        coupon.couponactive = true;
      }
    };
    const getcartdata = async () => {
      carttotal.totalprice = 0;
      carttotal.deliverycharge = 0;
      carttotal.deliverydetails = "";

      let deliverycharge = 0;
      let deliverydetails = "";

      let res = await axios.get("/getcartdata");
      cartdata.value = res.data;

      for (let i = 0; i < Object.keys(cartdata.value).length; i++) {
        if (cartdata.value[i].has_shipping_charge == true) {
          if (address.district == "Dhaka") {
            deliverycharge += parseFloat(cartdata.value[i].inside_dhaka);
            deliverydetails = "Inside Dhaka";
          } else if (address.district == "Others") {
            deliverycharge += parseFloat(cartdata.value[i].outside_dhaka);
            deliverydetails = "Outside Dhaka";
          } else {
            deliverycharge += 0;
          }
        }
        carttotal.totalprice =
          parseFloat(carttotal.totalprice) +
          parseFloat(cartdata.value[i].total);
      }
      if (coupon.total > 0) {
        carttotal.totalprice =
          parseFloat(carttotal.totalprice) -
          parseFloat(coupon.total) +
          parseFloat(deliverycharge);

        carttotal.deliverycharge = deliverycharge;
        carttotal.deliverydetails = deliverydetails;
      } else {
        carttotal.totalprice =
          parseFloat(carttotal.totalprice) + parseFloat(deliverycharge);

        carttotal.deliverycharge = deliverycharge;
        carttotal.deliverydetails = deliverydetails;
      }
    };

    const paynow = async () => {
      if (paymentd.terms == false) {
        toast.warning("Please check the Terms & Conditions.", {
          timeout: 2000,
        });
        return;
      }
      try {
        let res = await axios.post("/ssl-payment", carttotal);
        if (res.data.payload) {
          window.location.href = res.data.payload;
        }
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    };

    const payoncash = async () => {
      if (paymentd.terms == false) {
        toast.warning("Please check the Terms & Conditions.", {
          timeout: 2000,
        });
        return;
      }

      try {
        let res = await axios.post("/cashondelivery-process", carttotal);
        if (res.data.status) {
          window.location.href = res.data.data;
        }
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    }

    onMounted(getloggeduser());
    onMounted(getcoupondata());
    onMounted(getcartdata());

    return {
      cartdata,
      carttotal,
      loggeduser,
      newRegister,
      loginuser,
      verifyotp,
      checkOTP,
      register,
      login,
      address,
      districts,
      updateaddress,
      coupon,
      validatePhone,
      validatePassword,
      otp,
      paymentd,
      paynow,
      payoncash
    };
  },

};
</script>
