<template>
  <!-- main content -->
  <div class="main-content">
    <div class="wrap-banner">
      <!-- breadcrumb -->
      <nav class="breadcrumb-bg">
        <div class="container no-index">
          <div class="breadcrumb">
            <ol>
              <li>
                <a href="#">
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>My Account</span>
                </a>
              </li>
            </ol>
          </div>
        </div>
      </nav>

      <div class="acount head-acount">
        <div class="container">
          <div id="main">
            <div class="row">
              <div class="nov-html col-lg-6 col-sm-12 col-xs-12">
                <h1 class="title-page">My Account</h1>
                <div class="content" id="block-history">
                  <table class="std table">
                    <tbody>
                      <tr>
                        <th class="first_item">My Name :</th>
                        <td>{{ userdatails.name }}</td>
                      </tr>
                      <tr>
                        <th class="first_item">Username :</th>
                        <td>{{ userdatails.name }}</td>
                      </tr>
                      <tr>
                        <th class="first_item">Phone :</th>
                        <td>{{ userdatails.phone_no }}</td>
                      </tr>
                      <tr>
                        <th class="first_item">Email :</th>
                        <td>{{ userdatails.email }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="nov-html col-lg-6 col-sm-12 col-xs-12">
                <h1 class="title-page">Delivery Address</h1>
                <div class="content">
                  <VeeForm class="js-customer-form" id="customer-form" @submit="updateaddress">
                    <div>
                      <div class="form-group row">
                        <span class="font-weight-bold">Please enter your delivery address</span>
                      </div>

                      <div class="form-group row">
                        <div class="input-group js-parent-focus">
                          <VeeField name="address" type="text" class="form-control" placeholder="Delivery address"
                            v-model="address.address" rules="required" />
                          <ErrorMessage name="address" />
                        </div>
                      </div>
                      <div class="form-group">
                        <v-select label="districts" :options="districts" v-model="address.district"></v-select>
                      </div>
                    </div>
                    <div class="clearfix">
                      <div class="row">
                        <button class="continue btn btn-primary pull-xs-right" name="update" type="submit">
                          Update
                        </button>
                      </div>
                    </div>
                  </VeeForm>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="nov-html col-lg-12 col-sm-12 col-xs-12">
                <div class="order">
                  <h4>
                    Order
                    <span class="detail">History</span>
                  </h4>
                  <div id="block-history" class="block-center">
                    <table class="std table">
                      <thead>
                        <tr>
                          <th class="first_item">#</th>
                          <th class="first_item">Invoice No</th>
                          <th class="item mywishlist_first">Date</th>
                          <th class="item mywishlist_second">Promo</th>
                          <th class="item mywishlist_second">Amount</th>
                          <th class="item mywishlist_second">Payment Status</th>
                          <th class="item mywishlist_second">Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr id="wishlist_1" v-for="(invsc, ind) in invoices" :key="ind">
                          <td>{{ ind + 1 }}</td>
                          <td>
                            {{ invsc.invoice_no }}
                          </td>
                          <td>{{ $filters.dateFormat(invsc.created_at) }}</td>
                          <td v-if="invsc.has_promo == true">
                            {{ invsc.coupon.name }}
                          </td>
                          <td v-else>N/A</td>
                          <td>৳ {{ invsc.total }}</td>
                          <td>
                            <span v-if="invsc.payment_status == 1">Paid</span>
                            <span v-else>Unpaid</span>
                          </td>
                          <td>
                            <a :href="'/invoice/' + invsc.invoice_no" target="_blank">
                              Details</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive } from "vue";
import { useToast } from "vue-toastification";

export default {
  name: "Home",

  props: {
    msg: String,
  },
  setup() {
    const toast = useToast();
    const errors = ref([]);
    const userdatails = ref([]);
    const invoices = ref([]);
    const address = reactive({
      address: "",
      district: "",
      division: "",
      postcode: "",
    });
    const districts = ref(["Dhaka", "Others"]);

    const getUserDetails = async () => {
      try {
        let res = await axios.get("/get-user-details");
        userdatails.value = res.data;
        address.address = res.data.address.address_1;
        address.district = res.data.address.district;
        address.division = res.data.address.division;
        address.postcode = res.data.address.postcode;
        getUserInvoices();
        // console.log(res.data)
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    };

    const getUserInvoices = async () => {
      try {
        let res = await axios.get("/invoices");
        invoices.value = res.data;
        // console.log(res.data)
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    };

    const updateaddress = async () => {
      try {
        let res = await axios.post("/update-address", address);
        // userdatails.value = res.data;
        toast.success("Address updated successfully!", {
          timeout: 2000,
        });
        // console.log(res.data);
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    };

    onMounted(getUserDetails());

    return {
      userdatails,
      updateaddress,
      address,
      districts,
      invoices,
      errors,
      toast,
    };
  },
};
</script>


<style lang="scss">

</style>