require('./bootstrap')

import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VeeValidatePlugin from "./validation.js";

import Toast from "vue-toastification";

import Plugins from "./plugins.js";

import App from "./App.vue";
import router from './router';

import moment from 'moment';


const app = createApp(App);

app.use(VueAxios, axios);
app.use(VeeValidatePlugin);
const options = {
    // You can set your default options here
};

app.config.globalProperties.$filters = {
    dateFormat(date) {
        return moment(date).format('DD/MM/YYYY')
    },
};

app.use(Toast, options);

app.use(Plugins);
app.use(router);
app.mount('#app');