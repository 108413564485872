<template>
  <!-- main content -->
  <div class="main-content">
    <div id="wrapper-site">
      <div id="content-wrapper">
        <div id="main">
          <div class="page-home">
            <!-- breadcrumb -->
            <nav class="breadcrumb-bg">
              <div class="container no-index">
                <div class="breadcrumb">
                  <ol>
                    <li>
                      <a :href="'/'">
                        <span>Home</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>Return & Refund Policy:</span>
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
            </nav>
            <div class="container">
              <div class="content">
                <div class="row">
                  <div
                    class="col-sm-8 col-lg-9 col-md-9 flex-xs-first main-blogs"
                  >
                    <h3>Return & Refund Policy:</h3>

                    <div class="late-item">
                      
                      <p>
                        a)	You must return product within 3 calendar days after receiving the product. 
                      </p>
                      <p>
                        b)	We will not accept the product after 3 days of your receiving date.
                      </p>

                      <p>
                        c)	If the nature of your complaint refers to damages, we advise you to contact our customer care within 3 days with original invoice of customer copy.
                        <ol>
                            <li> &nbsp;&nbsp;&nbsp; Step 1: Check if your product meets all the conditions for returning a product.</li>
                            <li> &nbsp;&nbsp;&nbsp; Step 2: Contact SWAN GROUP Customer Service to submit a complaint / request for return & collect return form.</li>
                            <li> &nbsp;&nbsp;&nbsp; Step 3: Fill in the return form given with the invoice.</li>
                            <li> &nbsp;&nbsp;&nbsp; Step 4: Choose your preferred method of product return when you call Customer Service. As per discussion we will return our product to our warehouse.</li>
                            <li> &nbsp;&nbsp;&nbsp; Step 5: Your returned product will go through a Quality Check.</li>
                            <li> &nbsp;&nbsp;&nbsp; Step 6: If validated, you will get product Return / Replacement.</li>
                        </ol>
                      </p>
                      <p class="content-title">NOTE:</p>
                      <p>
                        
                        <ol>
                            <li> &nbsp;&nbsp;&nbsp; •	You must show the original Customer Copy of the Invoice to authenticate your purchase, at the time of handing over the product.</li>
                            <li> &nbsp;&nbsp;&nbsp; •	In case of customize order for example- Mattress which can not to replace & return like other product.</li>
                        </ol>
                      </p>

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>