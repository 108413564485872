<template>
  <!-- main content -->
  <div class="main-content" id="cart">
    <!-- main -->
    <div id="wrapper-site">
      <div id="content-wrapper">
        <!-- breadcrumb -->
        <nav class="breadcrumb-bg">
          <div class="container no-index">
            <div class="breadcrumb">
              <ol>
                <li>
                  <a :href="'/'">
                    <span>Home</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span>Shopping Cart</span>
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </nav>
        <div class="container">
          <div class="row">
            <div
              id="content-wrapper"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 onecol"
            >
              <section id="main">
                <div class="cart-grid row">
                  <div class="col-md-8 col-xs-12 check-info">
                    <h1 class="title-page">Shopping Cart</h1>
                    <div class="cart-container">
                      <div class="cart-overview js-cart">
                        <ul class="cart-items">
                          <li
                            class="cart-item"
                            v-for="(crtdata, indc) in cartdata"
                            :key="indc"
                          >
                            <div
                              class="
                                product-line-grid
                                row
                                justify-content-between
                              "
                            >
                              <!--  product left content: image-->
                              <div class="product-line-grid-left col-md-2">
                                <span class="product-image media-middle">
                                  <a :href="'/details/' + crtdata.product.slug">
                                    <img
                                      class="img-fluid"
                                      :src="
                                        '../../../../uploads/products/' +
                                        crtdata.product.featured_img
                                      "
                                      :alt="crtdata.product.name"
                                    />
                                  </a>
                                </span>
                              </div>
                              <div class="product-line-grid-body col-md-5">
                                <div class="product-line-info">
                                  <a
                                    class="label"
                                    href="#"
                                    data-id_customization="0"
                                    >{{ crtdata.product.name }}</a
                                  >
                                </div>
                                <div class="product-line-info product-price">
                                  <span class="value"
                                    >৳ {{ crtdata.primary_total }}</span
                                  >
                                </div>
                                <div
                                  v-for="(attribs, indat) in crtdata.details"
                                  :key="indat"
                                >
                                  <div
                                    class="product-line-info"
                                    v-if="attribs.attrib == 'length'"
                                  >
                                    <span class="label-atrr">Length: </span>
                                    <span class="value">
                                      {{ attribs.attrib_quantity }}″
                                    </span>
                                  </div>
                                  <div
                                    class="product-line-info"
                                    v-if="attribs.attrib == 'width'"
                                  >
                                    <span class="label-atrr">Width: </span>
                                    <span class="value">
                                      {{ attribs.attrib_quantity }}″
                                    </span>
                                  </div>
                                  <div
                                    class="product-line-info"
                                    v-if="attribs.attrib == 'height'"
                                  >
                                    <span class="label-atrr">Height: </span>
                                    <span class="value">
                                      {{ attribs.attrib_quantity }}″
                                    </span>
                                  </div>
                                  <div
                                    class="product-line-info"
                                    v-if="attribs.attrib == 'febrics'"
                                  >
                                    <span class="label-atrr">Febrics: </span>
                                    <span class="value">{{
                                      attribs.attrib_name
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="
                                  product-line-grid-right
                                  text-center
                                  product-line-actions
                                  col-md-5
                                "
                              >
                                <div class="row">
                                  <div class="col-md-5 col qty">
                                    <div class="label">Qty:</div>
                                    <div class="quantity">
                                      <input
                                        type="text"
                                        name="qty"
                                        v-model="crtdata.quantity"
                                        class="input-group form-control"
                                        v-on:change="
                                          calculatePrice(indc, crtdata.quantity)
                                        "
                                        disabled
                                      />

                                      <span class="input-group-btn-vertical">
                                        <button
                                          class="
                                            btn btn-touchspin
                                            js-touchspin
                                            bootstrap-touchspin-up
                                          "
                                          type="button"
                                          v-on:click="
                                            increaseqn(indc, crtdata.quantity)
                                          "
                                        >
                                          +
                                        </button>
                                        <button
                                          class="
                                            btn btn-touchspin
                                            js-touchspin
                                            bootstrap-touchspin-down
                                          "
                                          type="button"
                                          v-on:click="
                                            decreaseqn(
                                              indc,
                                              crtdata.quantity,
                                              crtdata.product.minimum_order
                                            )
                                          "
                                        >
                                          -
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="col-md-5 col price">
                                    <div class="label">Total:</div>
                                    <div class="product-price total">
                                      ৳ {{ crtdata.total }}
                                    </div>
                                  </div>
                                  <div
                                    class="
                                      col-md-2 col
                                      text-xs-right
                                      align-self-end
                                    "
                                  >
                                    <div class="cart-line-product-actions">
                                      <a
                                        class="remove-from-cart"
                                        rel="nofollow"
                                        data-link-action="delete-from-cart"
                                        data-id-product="1"
                                        v-on:click="deletecartdata(crtdata.id)"
                                      >
                                        <i
                                          class="fa fa-trash-o"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <a
                      v-on:click="checkout"
                      class="continue btn btn-primary pull-xs-right"
                    >
                      Checkout
                    </a>
                  </div>
                  <div class="cart-grid-right col-xs-12 col-lg-4">
                    <div class="cart-summary">
                      <div class="cart-detailed-totals">
                        <div class="cart-summary-products">
                          <div class="summary-label">Cart Summary</div>
                        </div>
                        <div
                          class="cart-summary-line"
                          id="cart-subtotal-products"
                        >
                          <span class="label js-subtotal">
                            Total Items: &nbsp;
                          </span>
                          <span class="value">{{ cartdata.length }}</span>
                        </div>
                        <div
                          class="cart-summary-line"
                          id="cart-subtotal-shipping"
                        >
                          <div
                            class="block-newsletter"
                            v-if="!coupontotal.couponactive"
                          >
                            <VeeForm class="pt-3" @submit="applycoupon">
                              <div class="input-group">
                                <VeeField
                                  type="text"
                                  class="form-control"
                                  name="coupon"
                                  placeholder="Coupon code"
                                  rules="min:3"
                                  v-model="coupontotal.couponcode"
                                />
                                <ErrorMessage name="coupon" />
                                <span class="input-group-btn">
                                  <button
                                    class="effect-btn btn btn-secondary"
                                    name="submitNewsletter"
                                    type="submit"
                                  >
                                    <span>Apply</span>
                                  </button>
                                </span>
                              </div>
                            </VeeForm>
                          </div>

                          <div v-else>
                            <span class="label">Coupon: &nbsp;</span>
                            <small class="value">{{
                              coupontotal.couponcode
                            }}</small>
                            <a
                              class="remove-from-cart"
                              rel="nofollow"
                              v-on:click="removecoupon()"
                            >
                              <i
                                class="fa fa-trash-o"
                                style="padding: 10px; color: #fd0404"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </div>
                        <div class="cart-summary-line cart-total">
                          <span class="label">Total: &nbsp;</span>
                          <span class="value"
                            >৳ {{ carttotal.totalprice }} (tax incl.)</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { ref, onMounted, reactive, computed } from "vue";

export default {
  setup() {
    const route = useRoute();
    const toast = useToast();
    const cartdata = ref([]);
    const carttotal = reactive({
      totalprice: 0,
    });
    const coupontotal = reactive({
      coupon: 0,
      couponcode: "",
      coupontype: "",
      amounttotal: 0,
      total: 0,
      couponactive: false,
    });
    const cartup = reactive({
      cartid: 0,
      cartpqn: 0,
      cartptotal: 0,
    });

    const getcoupondata = async () => {
      let res = await axios.get("/getsavedcoupon");
      if (res.data) {
        coupontotal.couponcode = res.data.couponcode[0];
        coupontotal.total = res.data.couponvalue[0];
        coupontotal.couponactive = true;
      }
    };

    const getcartdata = async () => {
      carttotal.totalprice = 0;
      let res = await axios.get("/getcartdata");
      cartdata.value = res.data;
      // console.log(cartdata.value);
      for (let i = 0; i < Object.keys(cartdata.value).length; i++) {
        carttotal.totalprice =
          parseFloat(carttotal.totalprice) +
          parseFloat(cartdata.value[i].total);
      }
      if (coupontotal.total > 0) {
        carttotal.totalprice =
          parseFloat(carttotal.totalprice - coupontotal.total).toFixed(2);
      }
    };

    const deletecartdata = async (cid) => {
      let res = await axios.delete("/delete-cartdata/" + cid);
      toast.success("Deleted fromcart!", {
        timeout: 2000,
      });

      getcartdata();
    };

    const calculatePrice = async (key, qn) => {
      carttotal.totalprice = 0;
      for (let i = 0; i < Object.keys(cartdata.value).length; i++) {
        if (i == key) {
          cartdata.value[i].quantity = qn;
          let rawprice = parseFloat(cartdata.value[i].primary_total);
          cartdata.value[i].total = (rawprice * qn).toFixed(2);
        }
        carttotal.totalprice =
          parseFloat(carttotal.totalprice) +
          parseFloat(cartdata.value[i].total);

        cartup.cartid = cartdata.value[i].id;
        cartup.cartpqn = cartdata.value[i].quantity;
        cartup.cartptotal = cartdata.value[i].total;

        const res = await axios.post("/update-cartdata", cartup);
        toast.success("Cart Updated!", {
          timeout: 2000,
        });
      }
      if (coupontotal.total > 0) {
        carttotal.totalprice =
          parseFloat(carttotal.totalprice - coupontotal.total).toFixed(2);
      }
    };
    const increaseqn = async (key, qn) => {
      calculatePrice(key, parseInt(qn) + 1);
      return;
    };

    const decreaseqn = async (key, qn, minqn) => {
      if (qn > minqn) {
        calculatePrice(key, parseInt(qn) - 1);
      }
      return;
    };

    const applycoupon = async (key) => {
      const res = await axios.post("/applycoupon", coupontotal);
      
      if (Object.keys(res.data).length === 0) {
        toast.warning("Invalid coupon.", {
          timeout: 2000,
        });
        return;
      } else {
        coupontotal.amounttotal = carttotal.totalprice;
        
        let ctype = res.data.type;
        let cval = res.data.value;
        if (ctype == "percent") {
          coupontotal.total = (carttotal.totalprice * cval) / 100;
          
        } else {
          coupontotal.total = cval;
        }
        // console.log(coupontotal.total);
        carttotal.totalprice = coupontotal.amounttotal - coupontotal.total;
        coupontotal.couponactive = true;
        if (coupontotal.total > 0) {
          const res = await axios.post("/savecoupon", coupontotal);
        }
        toast.success("Coupon applied!", {
          timeout: 2000,
        });
      }
    };

    const removecoupon = async (key) => {
      coupontotal.amounttotal = carttotal.totalprice;
      carttotal.totalprice = parseFloat(coupontotal.amounttotal + coupontotal.total).toFixed(2);

      const res = await axios.get("/deletesavedcoupon");

      coupontotal.coupon = 0;
      coupontotal.couponcode = "";
      coupontotal.coupontype = "";
      coupontotal.amounttotal = 0;
      coupontotal.total = 0;
      coupontotal.couponactive = false;

      toast.success("Coupon removed!", {
        timeout: 2000,
      });
    };

    const checkout = async () => {
      window.location.href = "/checkout";
    };

    onMounted(getcoupondata());
    onMounted(getcartdata());

    return {
      cartdata,
      carttotal,
      calculatePrice,
      increaseqn,
      decreaseqn,
      deletecartdata,
      coupontotal,
      applycoupon,
      removecoupon,
      checkout,
    };
  },
};
</script>