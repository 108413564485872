
import { UploadMedia, UpdateMedia } from 'vue-media-upload';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {

    install(app) {
        app.component('upload-media', UploadMedia);
        app.component('update-media', UpdateMedia);
        app.component('v-select', vSelect);
        app.component('Datepicker', Datepicker);        
    },

};
