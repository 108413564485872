<template>
    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">

            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Users</h4>
                    <p class="card-description">
                        Create/update User
                    </p>
                    <VeeForm class="pt-3" @submit="storeuser">
                        <div class="form-group">
                            <label for="exampleInputUsername1">Name</label>

                            <VeeField name="userfullname" type="text" class="form-control form-control-lg"
                                placeholder="User Full Name" v-model="user.userfullname" rules="required" />
                            <ErrorMessage name="userfullname" />
                        </div>
                        <div class="form-group">
                            <label for="exampleInputUsername1">Username</label>

                            <VeeField name="username" type="text" class="form-control form-control-lg"
                                placeholder="user Name" v-model="user.username" rules="required" />
                            <ErrorMessage name="username" />
                        </div>
                        <div class="form-group">
                            <label for="exampleInputUsername1">Password</label>

                            <VeeField name="password" type="text" class="form-control form-control-lg"
                                placeholder="user password" v-model="user.password" rules="required" />
                            <ErrorMessage name="password" />
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Roles</label>
                            <v-select label="name" :options="roles" :reduce="name => name.name" v-model="user.role"></v-select>
                        </div>


                        <button type="submit" class="btn btn-primary me-2">Submit</button>
                        <button class="btn btn-light">Cancel</button>
                    </VeeForm>
                </div>
            </div>

        </div>
    </div>
    <!-- content-wrapper ends -->
</template>


<script>
import { ref, onMounted, reactive } from 'vue';
import { useToast } from "vue-toastification";

export default {
    name: "CreateUser",

    props: {
        msg: String,
    },
    setup() {
        const toast = useToast();
        const errors = ref([]);
        const roles = ref([]);
        const user = reactive({
            userfullname: '',
            username: '',
            password: '',
            role: ''
        });

        const getroles = async () => {
            let res = await axios.get("/admin/get-all-roles");
            roles.value = res.data;
        }

        const storeuser = async () => {
            try {
                const res = await axios.post("/admin/store-user", user)
                toast.success("Created successfully!", {
                    timeout: 2000
                });
                // console.log(res)
                window.location.href = '/admin/users';
            } catch (e) {
                if (e.response.status == 422) {
                    var data = [];
                    for (const key in e.response.data.errors) {
                        data.push(e.response.data.errors[key][0]);
                    }
                    errors.value = data;
                }
            }
        }


        const formReset = () => {
            user.userfullname = '';
            user.username = '';
            user.password = '';
            user.role = [];
        }

        onMounted(getroles());

        return {
            user, roles, storeuser, errors, toast,
        }
    },
};
</script>


<style lang="scss">
@import "../../../../../node_modules/vue-good-table-next/src/styles/style.scss";
@import "../../../../../node_modules/vue-toastification/dist/index.css";
</style>
