<template>
  <!-- main content -->
  <div class="main-content">
    <div id="wrapper-site">
      <div id="content-wrapper">
        <div id="main">
          <div class="page-home">
            <!-- breadcrumb -->
            <nav class="breadcrumb-bg">
              <div class="container no-index">
                <div class="breadcrumb">
                  <ol>
                    <li>
                      <a :href="'/'">
                        <span>Home</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span>Product Details</span>
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
            </nav>
            <div class="container">
              <div class="content">
                <div class="row">
                  <div class="
                      sidebar-3 sidebar-collection
                      col-lg-3 col-md-3 col-sm-4
                    ">
                    <!-- category -->
                    <div class="sidebar-block">
                      <div class="title-block">Categories</div>
                      <div class="block-content">
                        <div class="cateTitle hasSubCategory open level1" v-for="(catg, intc) in categories"
                          :key="intc">
                          <span class="arrow collapse-icons collapsed" data-toggle="collapse"
                            :data-target="'#' + catg.slug">
                            <i class="zmdi zmdi-minus"></i>
                            <i class="zmdi zmdi-plus"></i>
                          </span>

                          <a class="cateItem" :href="'/shop/' + catg.slug">{{ catg.name }}</a>

                          <div class="subCategory collapse" :id="catg.slug" aria-expanded="true" role="status">
                            <div class="cateTitle" v-for="(catpro, intcp) in catg.products" :key="intcp">
                              <a :href="'/details/' + catpro.productdetails.slug" class="cateItem">{{
                                  catpro.product_name
                              }}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-8 col-lg-9 col-md-9">
                    <div class="main-product-detail">
                      <h2>{{ productdetails.name }}</h2>
                      <div class="product-single row">
                        <div class="product-detail col-xs-12 col-md-5 col-sm-5">
                          <div class="page-content" id="content">
                            <div class="images-container">
                              <div class="js-qv-mask mask tab-content border">
                                <div id="item1" class="tab-pane fade active in show">
                                  <vue-image-zoomer v-if="productdetails.featured_img" :regular="
                                    '../../../../uploads/products/' +
                                    productdetails.featured_img
                                  " img-class="img-fluid" />
                                </div>

                                <div :id="'item' + (indpi + 2)" class="tab-pane fade" v-for="(
                                    prodimg, indpi
                                  ) in productdetails.images" :key="indpi">
                                  <vue-image-zoomer v-if="prodimg.img_name" :regular="
                                    '../../../../uploads/products/' +
                                    prodimg.img_name
                                  " img-class="img-fluid" />
                                </div>

                                <div class="layer hidden-sm-down" data-toggle="modal" data-target="#product-modal">
                                  <i class="fa fa-expand"></i>
                                </div>
                              </div>
                              <ul class="product-tab nav nav-tabs d-flex">
                                <li class="active col">
                                  <a href="#item1" data-toggle="tab" aria-expanded="true" class="active show">
                                    <img v-if="productdetails.featured_img" :src="
                                      '../../../../uploads/products/' +
                                      productdetails.featured_img
                                    " alt="SWAN" />
                                  </a>
                                </li>
                                <li class="col" v-for="(
                                    prodimg, indpi
                                  ) in productdetails.images" :key="indpi">
                                  <a :href="'#item' + (indpi + 2)" data-toggle="tab">
                                    <img v-if="prodimg.img_name" :src="
                                      '../../../../uploads/products/' +
                                      prodimg.img_name
                                    " alt="img" />
                                  </a>
                                </li>
                              </ul>
                              <div class="modal fade" id="product-modal" role="dialog">
                                <div class="modal-dialog">
                                  <!-- Modal content-->
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <div class="modal-body">
                                        <div class="product-detail">
                                          <div>
                                            <div class="images-container">
                                              <div class="
                                                  js-qv-mask
                                                  mask
                                                  tab-content
                                                ">
                                                <div id="modal-item1" class="
                                                    tab-pane
                                                    fade
                                                    active
                                                    in
                                                    show
                                                  ">
                                                  <img v-if="
                                                    productdetails.featured_img
                                                  " :src="
  '../../../../uploads/products/' +
  productdetails.featured_img
" alt="SWAN" />
                                                </div>
                                                <div :id="
                                                  'modal-item' + (indpi + 2)
                                                " class="tab-pane fade" v-for="(
                                                    prodimg, indpi
                                                  ) in productdetails.images" :key="indpi">
                                                  <img v-if="prodimg.img_name" :src="
                                                    '../../../../uploads/products/' +
                                                    prodimg.img_name
                                                  " alt="SWAN" />
                                                </div>
                                              </div>
                                              <ul class="product-tab nav nav-tabs">
                                                <li class="active">
                                                  <a href="#modal-item1" data-toggle="tab" class="active show">
                                                    <img v-if="
                                                      productdetails.featured_img
                                                    " :src="
  '../../../../uploads/products/' +
  productdetails.featured_img
" alt="SWAN" />
                                                  </a>
                                                </li>
                                                <li v-for="(
                                                    prodimg, indpi
                                                  ) in productdetails.images" :key="indpi">
                                                  <a :href="
                                                    '#modal-item' +
                                                    (indpi + 2)
                                                  " data-toggle="tab">
                                                    <img v-if="prodimg.img_name" :src="
                                                      '../../../../uploads/products/' +
                                                      prodimg.img_name
                                                    " alt="SWAN" />
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="product-info col-xs-12 col-md-7 col-sm-7">
                          <div class="detail-description">
                            <div class="price-del">
                              <span class="price" v-if="productdetails.custom_price">৳
                                <span v-if="product.customprice == 0">
                                  0.00
                                </span>
                                <span v-else>{{ product.totalprice }}</span>
                              </span>
                              <span class="price" v-else>৳
                                <span v-if="product.totalprice == 0">{{
                                    product.price
                                }}</span>
                                <span v-else>{{ product.totalprice }}</span>
                              </span>

                              <span class="float-right" v-if="product.productstock > 0">
                                <span class="availb">Availability: </span>
                                <span class="check">
                                  <i class="fa fa-check-square-o" aria-hidden="true"></i>IN STOCK
                                </span>
                              </span>

                            </div>
                            <p class="description" v-for="(
                                proddesc, indpd
                              ) in productdetails.details" :key="indpd">
                              <span v-if="proddesc.details_type == 'short'">
                                <span v-html="proddesc.details"></span>
                              </span>
                            </p>
                            <div class="option has-border d-lg-flex size-color" v-if="productdetails.custom_price">
                              <div class="size">
                                <span class="size">Length (Inch):</span>
                                <input type="number" v-model="product.length" v-on:change="calculatePrice" />
                              </div>
                              <div class="size">
                                <span class="size">Width (Inch):</span>
                                <input type="number" v-model="product.width" v-on:change="calculatePrice" />
                              </div>
                              <div class="size">
                                <span class="size">Height (Inch):</span>
                                <select v-model="product.height" v-on:change="calculatePrice">
                                  <option :value="proht" v-for="(proht, indht) in customheight" :key="indht">
                                    {{ proht }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="option has-border d-lg-flex size-color"
                              v-if="productdetails.attribute_type == 'febrics'">
                              <div class="colors">
                                <p class="title">Select febrics :</p>
                                <span class="" v-for="(
                                    prodfeb, indf
                                  ) in productdetails.attributes" :key="indf">
                                  <input type="radio" :id="'cb' + (indf + 1)" v-model="product.febrics" :value="
                                    prodfeb.attribute_name +
                                    '_' +
                                    prodfeb.attribute_price
                                  " v-on:change="calculatePrice" />
                                  <label :for="'cb' + (indf + 1)"><img :src="
                                    '../../../../uploads/attributes/' +
                                    prodfeb.default_img
                                  " alt="SWAN" /></label>
                                </span>
                              </div>
                            </div>
                            <div class="option has-border d-lg-flex size-color"
                              v-if="productdetails.attribute_type == 'others'">
                              <div class="size">
                                <span class="size">Select Size : </span>

                                <select v-model="product.attributedet" v-on:change="calculatePrice">
                                  <option :value="
                                    attb.id + '_' + attb.attribute_price
                                  " v-for="(
                                      attb, aind
                                    ) in productdetails.attributes" :key="aind">
                                    {{ attb.attribute_name }}
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div class="has-border cart-area" v-if="product.productstock > 0">
                              <div class="product-quantity">
                                <div class="qty">
                                  <div class="input-group">
                                    <div class="quantity">
                                      <span class="control-label">QTY : </span>
                                      <input type="text" name="qty" id="quantity_wanted" v-model="product.quantity"
                                        class="input-group form-control" v-on:change="calculatePrice" />

                                      <span class="input-group-btn-vertical">
                                        <button class="
                                            btn btn-touchspin
                                            js-touchspin
                                            bootstrap-touchspin-up
                                          " type="button" v-on:click="increaseqn">
                                          +
                                        </button>
                                        <button class="
                                            btn btn-touchspin
                                            js-touchspin
                                            bootstrap-touchspin-down
                                          " type="button" v-on:click="decreaseqn">
                                          -
                                        </button>
                                      </span>
                                    </div>
                                    <span class="add">
                                      <button class="
                                          btn btn-primary
                                          add-to-cart add-item
                                        " data-button-action="add-to-cart" v-on:click="addtocart">
                                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                        <span>Add to cart</span>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                              <p class="product-minimal-quantity"></p>
                            </div>

                            <div class="content">
                              <p>
                                Categories :
                                <span class="content2">
                                  <a href="#" v-for="(
                                      prodca, indprc
                                    ) in productdetails.category" :key="indprc">{{ prodca.category_name }}</a>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="review">
                        <ul class="nav nav-tabs">
                          <li class="active">
                            <a data-toggle="tab" href="#description" class="active show">Description</a>
                          </li>
                        </ul>

                        <div class="tab-content">
                          <div id="description" class="tab-pane fade in active show">
                            <p v-for="(
                                proddesc, indpd
                              ) in productdetails.details" :key="indpd">
                              <span v-if="proddesc.details_type == 'long'">
                                <span v-html="proddesc.details"></span>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { watchEffect, ref, onMounted, reactive, computed } from "vue";

import { VueImageZoomer } from "vue-image-zoomer";
import "vue-image-zoomer/dist/style.css";

export default {
  components: {
    VueImageZoomer,
  },
  setup() {
    const route = useRoute();
    const toast = useToast();
    const categories = ref([]);
    const productid = route.params.id;
    const minimumqn = ref([]);
    const customheight = ref([]);
    const product = reactive({
      prodid: 0,
      length: 0,
      width: 0,
      height: 0,
      price: 0,
      febrics: 0,
      febricid: "",
      febricprice: 0,
      quantity: 1,
      customprice: 0,
      attributedet: 0,
      attributetype: "",
      attributeid: 0,
      attributeprice: 0,
      totalprice: 0,
      productstock: 0,
    });
    const productdetails = ref([]);

    const getcategories = async () => {
      let res = await axios.get("/listcategorieswithproducts");
      categories.value = res.data;
      //   console.log(categories)
    };

    const getproductdetails = async () => {
      let res = await axios.get("/getproductdetails/" + productid);
      if (Object.keys(res.data).length === 0) {
        window.location.href = "/";
      }

      productdetails.value = res.data;
      product.prodid = productdetails.value.id;
      product.productstock = productdetails.value.curr_stock;

      product.price = parseFloat(
        productdetails.value.selling_price * productdetails.value.minimum_order
      ).toFixed(2);

      product.totalprice = parseFloat(
        productdetails.value.selling_price * productdetails.value.minimum_order
      ).toFixed(2);

      customheight.value = JSON.parse(productdetails.value.custom_height);
      minimumqn.value = productdetails.value.minimum_order;
      product.quantity = minimumqn.value;
      // console.log(productdetails.value)
    };

    const calculatePrice = async () => {
      if (productdetails.value.attribute_type == "febrics") {
        if (productdetails.value.formula == "sft") {
          if (product.febrics != 0) {
            let febricsdet = product.febrics.toString().split("_");
            product.febricid = febricsdet[0];
            product.febricprice = febricsdet[1];
          }
          product.customprice = (
            ((product.length * product.width * 1) / 144) *
            parseFloat(product.febricprice)
          ).toFixed(2);
          product.totalprice = (
            ((product.length * product.width * 1) / 144) *
            parseFloat(product.febricprice) *
            product.quantity
          ).toFixed(2);
        } else if (productdetails.value.formula == "cft") {
          if (product.febrics != 0) {
            let febricsdet = product.febrics.toString().split("_");
            product.febricid = febricsdet[0];
            product.febricprice = febricsdet[1];
          }
          product.customprice = (
            ((product.length * product.width * parseFloat(product.height)) /
              1728) *
            parseFloat(product.febricprice)
          ).toFixed(2);
          product.totalprice = (
            ((product.length * product.width * parseFloat(product.height)) /
              1728) *
            parseFloat(product.febricprice) *
            product.quantity
          ).toFixed(2);
        }
      } else if (productdetails.value.attribute_type == "others") {
        let attrbdet = product.attributedet.toString().split("_");
        product.attributetype = productdetails.value.attribute_type;
        product.attributeid = attrbdet[0];
        product.attributeprice = attrbdet[1];

        product.totalprice = (
          parseFloat(product.attributeprice) * product.quantity
        ).toFixed(2);
        // console.log(attrbdet);
      } else {
        product.totalprice = (
          parseFloat(productdetails.value.selling_price) * product.quantity
        ).toFixed(2);
      }
    };

    const increaseqn = async () => {
      product.quantity++;
      calculatePrice();
      return;
    };

    const decreaseqn = async () => {
      if (product.quantity > minimumqn.value) {
        product.quantity--;
        calculatePrice();
      }
      return;
    };

    const addtocart = async () => {
      try {
        // console.log(product);
        if (productdetails.value.custom_price) {
          if (product.customprice > 0) {
            const res = await axios.post("/addtocartprocess", product);
            toast.success("Added to cart!", {
              timeout: 2000,
            });

            window.location.href = "/cart";
          } else {
            toast.warning("Please add product measurements!", {
              timeout: 2000,
            });
          }
        } else if (productdetails.value.attribute_type == "others") {
          if (product.attributeid > 0) {
            const res = await axios.post("/addtocartprocess", product);
            toast.success("Added to cart!", {
              timeout: 2000,
            });

            window.location.href = "/cart";
          } else {
            toast.warning("Please add attributes!", {
              timeout: 2000,
            });
          }
        } else {
          const res = await axios.post("/addtocartprocess", product);
          toast.success("Added to cart!", {
            timeout: 2000,
          });

          window.location.href = "/cart";
        }
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    };

    onMounted(getcategories());
    onMounted(getproductdetails());

    return {
      productid,
      product,
      productdetails,
      categories,
      customheight,
      increaseqn,
      decreaseqn,
      calculatePrice,
      addtocart,
    };
  },
};
</script>
<style lang="scss">
#app .vh--message {
  display: none;
}
</style>

