<template>
  <!-- main content -->
  <div class="main-content">
    <div class="wrap-banner">
      <!-- menu category -->
      <div class="menu-banner d-xs-none">
        <div class="tiva-verticalmenu block" data-count_showmore="17">
          <div class="box-content block_content">
            <div class="verticalmenu" role="navigation">
              <ul class="menu level1">
                <h3 class="title-block">CATEGORIES</h3>
                <li
                  class="item parent"
                  v-for="(catlist, indc) in categories"
                  :key="indc"
                >
                  <a href="#" class="hasicon" :title="catlist.name">
                    <img
                      :src="
                        '../../../uploads/categories/' + catlist.featured_img
                      "
                      alt="img"
                    />
                    {{ catlist.name }}
                  </a>
                </li>
              </ul>
            </div>
            <div
              class="
                d-flex
                justify-content-center
                align-items-center
                header-top-left
                pull-left
              "
            >
              <div class="toggle-nav act">
                <div class="btnov-lines-large">
                  <i class="zmdi zmdi-close"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- main -->
    <div id="wrapper-site">
      <div id="content-wrapper" class="full-width">
        <div id="main">
          <section class="page-home">
            <swiper
              class="swiper"
              :modules="modules"
              :space-between="30"
              :loop="true"
              :navigation="true"
              :autoplay="{
                delay: 5500,
                disableOnInteraction: false,
              }"
            >
              <swiper-slide v-for="(slide, ind) in banners" :key="ind">
                <img
                  class="img-fluid img-responsive"
                  :src="'../../../uploads/banners/' + slide.banner_img"
                  :alt="slide.title"
                />
              </swiper-slide>
            </swiper>

            <!-- product living room -->
            <div class="section living-room background-none">
              <div class="container">
                <div class="tiva-row-wrap row">
                  <div class="col-md-12 col-xs-12 groupcategoriestab-vertical">
                    <div class="grouptab">
                      <div class="product-tab categoriestab-left flex-9">
                        <div class="title-tab-content d-flex">
                          <!-- tab product -->
                          <div
                            class="
                              dropdown-toggle
                              toggle-category
                              tab-category-none
                            "
                          >
                            Select Category
                          </div>
                          <ul class="nav nav-tabs wibkit row">
                            <li class="col-xs-6">
                              <a href="#all" data-toggle="tab" class="active"
                                >ALL PRODUCTS</a
                              >
                            </li>
                            <li
                              class="col-xs-6"
                              v-for="(cat, indc) in categories"
                              :key="indc"
                            >
                              <a :href="'#' + cat.slug" data-toggle="tab">{{
                                cat.name
                              }}</a>
                            </li>
                          </ul>
                        </div>

                        <!-- tab product content -->
                        <div class="tab-content">
                          <div id="all" class="tab-pane fade in active show">
                            <div class="item text-center row">
                              <div
                                class="col-md-3 col-xs-12"
                                v-for="(prod, indp) in products"
                                :key="indp"
                              >
                                <div
                                  class="
                                    product-miniature
                                    js-product-miniature
                                    item-one
                                    first-item
                                  "
                                >
                                  <div class="thumbnail-container">
                                    <a :href="'/details/' + prod.slug">
                                      <img v-if="prod.featured_img"
                                        class="img-fluid"
                                        :src="
                                          '../../../uploads/products/' +
                                          prod.featured_img
                                        "
                                        alt="img"
                                      />
                                    </a>
                                    <!-- <div class="product-flags discount">-30%</div> -->
                                  </div>
                                  <div class="product-description">
                                    <div class="product-groups">
                                      <div class="product-title">
                                        <a :href="'/details/' + prod.slug">{{
                                          prod.name
                                        }}</a>
                                      </div>

                                      <div class="product-group-price">
                                        <div class="product-price-and-shipping">
                                          <span class="price">{{
                                            prod.default_size
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="product-group-price">
                                        <div class="product-price-and-shipping">
                                          <span class="price"
                                            >৳{{ prod.selling_price }}</span
                                          >
                                          <!-- <del class="regular-price">৳{{ prod.selling_price }}</del> -->
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="
                                        product-buttons
                                        d-flex
                                        justify-content-center
                                      "
                                    >
                                      <a
                                        :href="'/details/' + prod.slug"
                                        class="quick-view hidden-sm-down"
                                        data-link-action="quickview"
                                      >
                                        <i
                                          class="fa fa-shopping-cart"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            :id="catp.slug"
                            class="tab-pane fade"
                            v-for="(catp, indc) in productswithcat"
                            :key="indc"
                          >
                            <div class="item text-center row">
                              <div
                                class="col-md-3 col-xs-12"
                                v-for="(prodcp, indpcp) in catp.products"
                                :key="indpcp"
                              >
                                <div
                                  class="
                                    product-miniature
                                    js-product-miniature
                                    item-one
                                    first-item
                                  "
                                >
                                  <div class="thumbnail-container">
                                    <a
                                      :href="
                                        '/details/' + prodcp.productdetails.slug
                                      "
                                    >
                                      <img v-if="prodcp.productdetails.featured_img"
                                        class="img-fluid"
                                        :src="
                                          '../../../uploads/products/' +
                                          prodcp.productdetails.featured_img
                                        "
                                        alt="img"
                                      />
                                    </a>
                                    <!-- <div class="product-flags discount">-30%</div> -->
                                  </div>
                                  <div class="product-description">
                                    <div class="product-groups">
                                      <div class="product-title">
                                        <a
                                          :href="
                                            '/details/' +
                                            prodcp.productdetails.slug
                                          "
                                          >{{ prodcp.productdetails.name }}</a
                                        >
                                      </div>

                                      <div class="product-group-price">
                                        <div class="product-price-and-shipping">
                                          <span class="price"
                                            >৳{{
                                              prodcp.productdetails
                                                .selling_price
                                            }}</span
                                          >
                                          <del class="regular-price"
                                            >৳{{
                                              prodcp.productdetails
                                                .selling_price
                                            }}</del
                                          >
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="
                                        product-buttons
                                        d-flex
                                        justify-content-center
                                      "
                                    >
                                      <form
                                        action="index.html"
                                        method="post"
                                        class="formAddToCart"
                                      >
                                        <input type="hidden" name="token" />
                                        <a
                                          class="add-to-cart"
                                          href="#"
                                          data-button-action="add-to-cart"
                                        >
                                          <i
                                            class="fa fa-shopping-cart"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </form>
                                      <a
                                        class="addToWishlist wishlistProd_1"
                                        href="#"
                                        data-rel="1"
                                        onclick=""
                                      >
                                        <i
                                          class="fa fa-heart"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                      <a
                                        href="#"
                                        class="quick-view hidden-sm-down"
                                        data-link-action="quickview"
                                      >
                                        <i
                                          class="fa fa-eye"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { Navigation, Autoplay} from "swiper";
import { Swiper, SwiperSlide} from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";

export default {
  name: "Welcome",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const categories = ref([]);
    const banners = ref([]);
    const products = ref([]);
    const productswithcat = ref([]);

    const getcategories = async () => {
      let res = await axios.get("/listproductcategories");
      categories.value = res.data;
    };

    const getbanners = async () => {
      let res = await axios.get("/listbanners");
      banners.value = res.data;
    };

    const getproducts = async () => {
      let res = await axios.get("/listproducts");
      products.value = res.data;
    };

    const getcategorywiseproducts = async () => {
      let res = await axios.get("/listcategorieswithproducts");
      productswithcat.value = res.data;
    };

    onMounted(getcategories());
    onMounted(getbanners());
    onMounted(getproducts());
    onMounted(getcategorywiseproducts());

    return {
      categories,
      banners,
      products,
      productswithcat,
      modules: [Navigation, Autoplay],
    };
  },
};
</script>

<style scoped>
.swiper-button-next, .swiper-button-prev{color: #000;}
</style>
