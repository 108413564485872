<template>
    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">

            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Attribute</h4>
                    <p class="card-description">
                        Create/update Attribute
                    </p>
                    <VeeForm class="pt-3" @submit="storeattribute">
                        <div class="form-group">
                            <label for="exampleInputUsername1">Name</label>

                            <VeeField name="attributename" type="text" class="form-control form-control-lg"
                                placeholder="Attribute Name" v-model="attribute.attributename" rules="required" />
                            <ErrorMessage name="attributename" />
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Price</label>
                            <VeeField name="attributprice" type="number" class="form-control form-control-lg" step="0.01"
                                placeholder="Attribute Price" v-model="attribute.attributprice" rules="required" />
                            <ErrorMessage name="attributprice" />
                        </div>
                        <div class="form-group">
                            <UploadMedia :error="imgerror" :server="imgserver" @media='ImgMedia' />
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">Type</label>
                            <v-select label="name" :options="types" v-model="attribute.type"></v-select>
                        </div>


                        <button type="submit" class="btn btn-primary me-2">Submit</button>
                        <button class="btn btn-light">Cancel</button>
                    </VeeForm>
                </div>
            </div>

        </div>
    </div>
    <!-- content-wrapper ends -->
</template>


<script>
import { ref, onMounted, reactive } from 'vue';
import { useToast } from "vue-toastification";
import { UploadMedia, UpdateMedia } from "vue-media-upload";
export default {
    name: "CreateCategories",
    components: {
        UploadMedia,
        UpdateMedia
    },
    props: {
        msg: String,
    },
    setup() {
        const toast = useToast();
        const errors = ref([]);
        const types = ref(['febrics', 'others']);
        const attribute = reactive({
            attributename: '',
            attributprice: '',
            attributemedia: [],
            type: ''
        });

        const storeattribute = async () => {
            try {
                const res = await axios.post("/admin/store-attribute", attribute)
                toast.success("Created successfully!", {
                    timeout: 2000
                });
                // console.log(res)
                window.location.href = '/admin/attributes';
            } catch (e) {
                if (e.response.status == 422) {
                    var data = [];
                    for (const key in e.response.data.errors) {
                        data.push(e.response.data.errors[key][0]);
                    }
                    errors.value = data;
                }
            }
        }

        const ImgMedia = async (value) => {
            attribute.attributemedia = value;
            // console.log(attribute.attributemedia)
        }

        const formReset = () => {
            attribute.attributename = '';
            attribute.attributedetails = '';
        }

        return {
            attribute, types,
            storeattribute, errors,
            toast, ImgMedia, imgerror: '', imgserver: '/admin/uploadimage',
        }
    },
};
</script>


<style lang="scss">
@import "../../../../../node_modules/vue-good-table-next/src/styles/style.scss";
@import "../../../../../node_modules/vue-toastification/dist/index.css";
</style>
