<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Shipping</h4>
          <p class="card-description">Create/update shipping</p>
          <VeeForm class="pt-3" @submit="updateshipping">
            <div class="form-group">
              <label for="exampleInputUsername1">Place Name</label>

              <VeeField
                name="shippingname"
                type="text"
                class="form-control form-control-lg"
                placeholder="Shipping Place Name"
                v-model="shipping.shippingname"
                rules="required"
              />
              <ErrorMessage name="shippingname" />
            </div>
            <div class="form-group">
              <label for="exampleInputUsername1">Rate</label>

              <VeeField
                name="shippingvalue"
                type="number"
                step="0.01"
                class="form-control form-control-lg"
                placeholder="Shipping Value"
                v-model="shipping.shippingvalue"
                rules="required"
              />
              <ErrorMessage name="sort" />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Notes</label>
              <VeeField
                name="shippingnotes"
                type="text"
                class="form-control form-control-lg"
                placeholder="shipping Details"
                v-model="shipping.shippingnotes"
              />
              <ErrorMessage name="shippingnotes" />
            </div>

            <div class="form-group">
              <label for="exampleInputUsername1">Status</label>

              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="shipping.status" />
                  Active
                  <i class="input-helper"></i
                ></label>
              </div>
            </div>

            <button type="submit" class="btn btn-primary me-2">Submit</button>
            <button class="btn btn-light">Cancel</button>
          </VeeForm>
        </div>
      </div>
    </div>
  </div>
  <!-- content-wrapper ends -->
</template>


<script>
import { ref, onMounted, reactive } from "vue";
import { useToast } from "vue-toastification";
import { useCookies } from "vue3-cookies";

export default {
  name: "UpdateShipping",

  props: {
    msg: String,
  },
  setup() {
    const toast = useToast();
    const errors = ref([]);
    const { cookies } = useCookies();

    const shipping = reactive({
      shippingid: cookies.get("shipv"),
      shippingname: "",
      shippingnotes: "",
      shippingvalue: 0,
      status: 0,
    });

    const getshipping = async () => {
      let res = await axios.get("/admin/get-shipping/" + shipping.shippingid);
      shipping.shippingname = res.data.name;
      shipping.shippingnotes = res.data.notes;
      shipping.shippingvalue = res.data.rate;
      shipping.status = res.data.status != 1 ? false : true;
    };

    const updateshipping = async () => {
      try {
        const res = await axios.post("/admin/update-shipping", shipping);
        toast.success("Shipping created successfully!", {
          timeout: 2000,
        });
        // console.log(res)
        getshipping();
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    };

    const formReset = () => {
      shipping.shippingname = "";
      shipping.shippingnotes = "";
      shipping.shippingvalue = 0;
    };

    onMounted(getshipping());

    return {
      shipping,
      updateshipping,
      errors,
      toast,
    };
  },
};
</script>


<style lang="scss">
@import "../../../../../node_modules/vue-good-table-next/src/styles/style.scss";
@import "../../../../../node_modules/vue-toastification/dist/index.css";
</style>
