<template>
    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">

            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Coupon</h4>
                    <p class="card-description">
                        Create/update coupon
                    </p>
                    <VeeForm class="pt-3" @submit="storecoupon">

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Name</label>

                                    <VeeField name="couponname" type="text" class="form-control form-control-lg"
                                        placeholder="coupon Name" v-model="coupon.couponname" rules="required" />
                                    <ErrorMessage name="couponname" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Details</label>
                                    <VeeField name="coupondetails" type="text" class="form-control form-control-lg"
                                        placeholder="coupon Details" v-model="coupon.coupondetails" rules="required" />
                                    <ErrorMessage name="coupondetails" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Start Date</label>
                                    <Datepicker v-model="coupon.couponstartfrom" autoApply :format="format" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">End date</label>
                                    <Datepicker v-model="coupon.couponendsat" autoApply :format="format" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Discount Type</label>
                                    <v-select label="name" :options="coupontype" v-model="coupon.coupontype"></v-select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Value</label>

                                    <VeeField name="couponvalue" type="number" step="0.01"
                                        class="form-control form-control-md" placeholder="Coupon Value"
                                        v-model="coupon.couponvalue" rules="required" />
                                    <ErrorMessage name="couponvalue" />
                                </div>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary me-2">Submit</button>
                        <button class="btn btn-light">Cancel</button>
                    </VeeForm>
                </div>
            </div>

        </div>
    </div>
    <!-- content-wrapper ends -->
</template>


<script>
import { ref, onMounted, reactive } from 'vue';
import { useToast } from "vue-toastification";


export default {
    name: "CreateCoupon",

    props: {
        msg: String,
    },
    setup() {
        const toast = useToast();
        const errors = ref([]);
        const coupontype = ref(['percent', 'amount']);
        const coupon = reactive({
            couponname: '',
            coupondetails: '',
            coupontype: [],
            couponvalue: '',
            couponstartfrom: '',
            couponendsat: '',
        });


        onMounted(() => {
            coupon.couponstartfrom = new Date();
            coupon.couponendsat = new Date(new Date().setDate(coupon.couponstartfrom.getDate() + 7));
        })
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }


        const storecoupon = async () => {
            try {
                const res = await axios.post("/admin/store-coupon", coupon)
                toast.success("coupon created successfully!", {
                    timeout: 2000
                });
                // console.log(res)
                window.location.href = '/admin/coupons';
            } catch (e) {
                if (e.response.status == 422) {
                    var data = [];
                    for (const key in e.response.data.errors) {
                        data.push(e.response.data.errors[key][0]);
                    }
                    errors.value = data;
                }
            }
        }

        const formReset = () => {
            coupon.couponname = '';
            coupon.coupondetails = '';
        }

        return {
            coupon, storecoupon, coupontype, format,
            errors, toast,
        }
    },
};
</script>


<style lang="scss">
@import "../../../../../node_modules/vue-good-table-next/src/styles/style.scss";
@import "../../../../../node_modules/vue-toastification/dist/index.css";
</style>
