<template>
  <!-- main content -->
  <div class="main-content">
    <div id="wrapper-site">
      <div id="content-wrapper">
        <!-- breadcrumb -->
        <nav class="breadcrumb-bg">
          <div class="container no-index">
            <div class="breadcrumb">
              <ol>
                <li>
                  <a :href="'/'">
                    <span>Home</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span>Board of Directors</span>
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </nav>

        <div id="main">
          <div class="page-home">
            <div class="container">
              <div class="about-us-content">
                <h1 class="title-page">Board of Directors</h1>
                <div class="row">
                  <div class="col-lg-8 col-md-8 col-sm-8 right">
                    <div class="cms-block f-left" style="margin-top: 1rem">
                      <h4>Khabeer Uddin Khan</h4>
                      <h6>Chairman & Managing Director</h6>
                      <br />
                      <ol>
                        <li><strong>Born:</strong> 01 February,1955</li>
                        <li>
                          <strong>Education:</strong> Hons & Masters in
                          Accounting from Dhaka University
                        </li>
                      </ol>

                      <h5>Present Position:</h5>
                      <ol>
                        <li>
                          <strong>Swan Industries Ltd.:</strong> Chairman &
                          Managing Director
                        </li>
                        <li>
                          <strong>Swan Chemicals Ltd.:</strong> Chairman &
                          Managing Director
                        </li>
                        <li><strong>Swan Properties Ltd.:</strong> Chairman</li>
                        <li><strong>Swan Traders:</strong> Proprietor</li>
                        <li><strong>Swan Associates:</strong> Proprietor</li>
                      </ol>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4 left">
                    <div class="cms-block f-right" >
                      <img
                        class="img-fluid"
                        :src="'../../../assets/img/members/unnamed1.jpg'"
                        alt="Khabeer Uddin Khan"
                        style="width: 70%"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 right">
                    <div class="cms-block f-left" style="margin-top: 1rem">
                      <h5>Professional Experience:</h5>
                      <p>
                        Became Entrepreneurs from day one since 1978 & gathered
                        lot of experiences for managing a company and able to
                        perform disaster management during crisis period. I use
                        to delivery my lectures in different Organizations like
                        Bank, The Chamber of Commerce Industry as well as Dhaka
                        University where I share my practical experiences and
                        try to motivate people to be Entrepreneurs. 33 Years
                        doing business with Brand reputation.
                      </p>

                      <div class="" id="block-history">
                        <h5>Membership of Professional Societies:</h5>
                        <table class="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th scope="col">SL#</th>
                              <th scope="col">Position</th>
                              <th scope="col">Name of the Organaization</th>
                              <th scope="col">Duration</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>Member & Co-convenor</td>
                              <td>The Dhaka Chamber of Commerce & Industry.</td>
                              <td>1995 till to-day</td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>Chairman</td>
                              <td>
                                Social Devlopment & Proper Education Programmein
                                native village Gopalgong
                              </td>
                              <td>2000 till to-day</td>
                            </tr>
                            <tr>
                              <th scope="row">3</th>
                              <td>President</td>
                              <td>Dhaka University Alumni Association.</td>
                              <td>2007-2008</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" style="margin-top: 2rem">
                  <div class="col-lg-4 col-md-4 col-sm-4 right">
                    <div class="cms-block f-left" style="margin-top: 1rem">
                      <h4>Roksana Khan</h4>
                      <h6>Director</h6>
                      <br />
                      <ol>
                        <li><strong>Born:</strong> 31 August 1952</li>
                        <li>
                          <strong>Education:</strong> Hons & Masters in Bangla
                          from Dhaka University
                        </li>
                      </ol>

                      <h5>Present Position:</h5>
                      <ol>
                        <li><strong>Swan Industries Ltd.:</strong> Director</li>
                        <li><strong>Swan Chemicals Ltd.:</strong> Director</li>
                        <li>
                          <strong>Swan Properties Ltd.:</strong> Managing
                          Director
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 left">
                    <div class="cms-block f-right" style="margin-top: 1rem">
                      <img
                        class="img-fluid"
                        :src="'../../../assets/img/members/unnamed.jpg'"
                        alt="Roksana Khan"
                        style="width: 30%"
                      />
                    </div>
                  </div>
                </div>

                <h1 class="title-page" style="margin-top: 5rem">Share Holders</h1>

                <div class="row" style="margin-top: 2rem">
                  <div class="col-lg-4 col-md-4 col-sm-4 right">
                    <div class="cms-block f-left" style="margin-top: 1rem">
                      <h4>Lameeya Farzana Khan</h4>
                      <h6>Share Holder</h6>
                      <br />
                      <ol>
                        <li><strong>Born:</strong> 28 January 1984</li>
                        <li>
                          <strong>Education:</strong> M.B.B.S from Banagladesh Medical; Now study in UK.
                        </li>
                      </ol>

                      <h5>Present Position:</h5>
                      <ol>
                        <li><strong>Swan Industries Ltd.:</strong> Share Holder</li>
                        <li><strong>Swan Chemicals Ltd.:</strong> Share Holder</li>
                      </ol>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 left">
                    <div class="cms-block f-right" style="margin-top: 1rem">
                      <img
                        class="img-fluid"
                        :src="'../../../assets/img/members/unnamed4.jpg'"
                        alt="Lameeya Farzana Khan"
                        style="width: 30%"
                      />
                    </div>
                  </div>
                </div>

                <div class="row" style="margin-top: 2rem">
                  <div class="col-lg-4 col-md-4 col-sm-4 right">
                    <div class="cms-block f-left" style="margin-top: 1rem">
                      <h4>Ismail Tareen Khan</h4>
                      <h6>Share Holder</h6>
                      <br />
                      <ol>
                        <li><strong>Born:</strong> 01 April 1985</li>
                        <li>
                          <strong>Education:</strong> M B A From Dhaka University
                        </li>
                      </ol>

                      <h5>Present Position:</h5>
                      <ol>
                        <li><strong>Swan Industries Ltd.:</strong> Share Holder</li>
                        <li><strong>Swan Chemicals Ltd.:</strong> Share Holder</li>
                      </ol>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 left">
                    <div class="cms-block f-right" style="margin-top: 1rem">
                      <img
                        class="img-fluid"
                        :src="'../../../assets/img/members/unnamed2.jpg'"
                        alt="Ismail Tareen Khan"
                        style="width: 30%"
                      />
                    </div>
                  </div>
                </div>

                <div class="row" style="margin-top: 2rem">
                  <div class="col-lg-4 col-md-4 col-sm-4 right">
                    <div class="cms-block f-left" style="margin-top: 1rem">
                      <h4>Ismail Tanzeer Khan</h4>
                      <h6>Share Holder</h6>
                      <br />
                      <ol>
                        <li><strong>Born:</strong> 03 February 1988</li>
                        <li>
                          <strong>Education:</strong> L.L.B & L.L.M completed from Sheffeld University in U.K.
                        </li>
                      </ol>

                      <h5>Present Position:</h5>
                      <ol>
                        <li><strong>Swan Industries Ltd.:</strong> Share Holder</li>
                        <li><strong>Swan Chemicals Ltd.:</strong> Share Holder</li>
                      </ol>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8 left">
                    <div class="cms-block f-right" style="margin-top: 1rem">
                      <img
                        class="img-fluid"
                        :src="'../../../assets/img/members/unnamed3.jpg'"
                        alt="Ismail Tanzeer Khan"
                        style="width: 30%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>