import { Form as VeeForm, Field as VeeField, ErrorMessage, defineRule } from "vee-validate";
import { required, email, min } from '@vee-validate/rules';


defineRule('required', value => {
    if (!value || !value.length) {
        return 'This field is required';
    }
    return true;
});


export default {
    install(app) {
        app.component("VeeForm", VeeForm);
        app.component("VeeField", VeeField);
        app.component("ErrorMessage", ErrorMessage);
        
        defineRule('required', required);
        defineRule('email', email);
        defineRule('min', min);
    },

};

