<template>
  <!-- main content -->
  <div class="main-content">
    <div class="wrap-banner">
      <!-- breadcrumb -->
      <nav class="breadcrumb-bg">
        <div class="container no-index">
          <div class="breadcrumb">
            <ol>
              <li>
                <a href="#">
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>Invoice</span>
                </a>
              </li>
            </ol>
          </div>
        </div>
      </nav>

      <div class="acount head-acount">
        <div class="container">
          <div id="main">
            <div class="container">
              <section class="card p-3">
                <div class="card-body">
                  <!-- Invoice Company Details -->
                  <div id="invoice-company-details" class="row">
                    <div class="col-md-6 col-sm-12 text-center text-md-left">
                      <div class="media">
                        <div class="media-body">
                          <ul class="ml-2 px-0 list-unstyled">
                            <li>
                              <img
                                :src="'https://swan.com.bd/assets/img/logo_200.png'"
                                alt="SWAN"
                                class="height-50"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 text-center text-md-right">
                      <h2>INVOICE</h2>
                      <p class="pb-3"># {{ invoice.invoice_no }}</p>
                      <ul class="px-0 list-unstyled">
                        <li>Bill amount</li>
                        <li class="lead text-bold-800">
                          ৳ {{ invoice.subtotal }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!--/ Invoice Company Details -->
                  <!-- Invoice Customer Details -->
                  <div id="invoice-customer-details" class="row pt-2">
                    <div class="col-sm-12 text-center text-md-left">
                      <p class="text-muted">Bill To</p>
                    </div>
                    <div class="col-md-6 col-sm-12 text-center text-md-left">
                      <ul class="px-0 list-unstyled">
                        <li class="text-bold-800">{{ customer.firstname }}</li>
                        <li>{{ customeraddress.address_1 }}</li>
                        <li>{{ customeraddress.district }}</li>
                        <li>
                          {{ customeraddress.district }}-{{
                            customeraddress.postcode
                          }}
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-6 col-sm-12 text-center text-md-right">
                      <p>
                        <span class="text-muted">Invoice Date :</span>
                        {{ $filters.dateFormat(invoice.created_at) }}
                      </p>
                      <p>
                        <span class="text-muted">Terms :</span> Paid on Receipt
                      </p>
                    </div>
                  </div>
                  <!--/ Invoice Customer Details -->
                  <!-- Invoice Items Details -->
                  <div id="invoice-items-details" class="pt-2">
                    <div class="row">
                      <div class="table-responsive col-sm-12">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Item &amp; Description</th>
                              <th class="text-right">Quantity</th>
                              <th class="text-right">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(itdesc, ind) in invoice.details"
                              :key="ind"
                            >
                              <th scope="row">{{ ind + 1 }}</th>
                              <td>
                                <p>{{ itdesc.product.name }}</p>
                                <p class="text-muted"></p>
                              </td>
                              <td class="text-right">{{ itdesc.quantity }}</td>
                              <td
                                class="text-right"
                                v-if="itdesc.is_custom == true"
                              >
                                ৳ {{ itdesc.prod_custom_price }}
                              </td>
                              <td class="text-right" v-else>
                                ৳ {{ itdesc.prod_base_price }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-7 col-sm-12 text-center text-md-left">
                        <p class="lead">Payment Methods:</p>
                        <div class="row">
                          <div class="col-md-8">
                            <table class="table table-borderless table-sm">
                              <tbody>
                                <tr>
                                  <td>Payment method:</td>
                                  <td class="text-right">
                                    Cash on delivery
                                    <!-- <img
                                      style="margin-top: -15px"
                                      height="50"
                                      src="https://www.sslcommerz.com/wp-content/uploads/2020/07/sslcom-pso.png"
                                      class="
                                        vc_single_image-img
                                        attachment-full
                                      "
                                      alt=""
                                      loading="lazy"
                                      title="sslcom-pso"
                                      srcset="
                                        https://www.sslcommerz.com/wp-content/uploads/2020/07/sslcom-pso.png        529w,
                                        https://www.sslcommerz.com/wp-content/uploads/2020/07/sslcom-pso-300x76.png 300w
                                      "
                                      sizes="(max-width: 529px) 100vw, 529px"
                                    /> -->
                                  </td>
                                </tr>
                                <tr>
                                  <td>Transection ID:</td>
                                  <td class="text-right">
                                    <!-- {{ invoicepayment.payment_id }} -->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5 col-sm-12">
                        <p class="lead">Summary:</p>
                        <div class="table-responsive">
                          <table class="table">
                            <tbody>
                              <tr>
                                <td>Sub Total</td>
                                <td class="text-right">
                                  ৳ {{ invoice.subtotal }}
                                </td>
                              </tr>
                              <tr>
                                <td>TAX & VAT</td>
                                <td class="text-right">৳ 0</td>
                              </tr>
                              <tr v-if="invoice.has_promo == true">
                                <td>Coupon</td>
                                <td class="pink text-right">
                                  ৳ {{ invoice.discount_amount }}
                                  <small>({{ invoicecoupon.name }})</small>
                                </td>
                              </tr>
                              <tr>
                                <td>Shipping charge</td>
                                <td class="pink text-right">
                                  ৳ {{ invoice.shipping_charge }}
                                  <small
                                    >({{ invoice.shipping_details }})</small
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td class="text-bold-800">Total</td>
                                <td class="text-bold-800 text-right">
                                  ৳ {{ invoice.total }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Invoice Footer -->
                  <div id="invoice-footer">
                    <div class="row">
                      <div class="col-md-7 col-sm-12">
                        <h6>Refund &amp; Policy</h6>
                        <p>
                          The required refund details need to mentioned on the
                          refund policy along with the standard timeline. The
                          standard timeline for refund is 7 to 10 working days.
                        </p>
                      </div>
                      <div class="col-md-5 col-sm-12 text-center">
                        <button
                          type="button"
                          class="btn btn-primary btn-lg my-1"
                          @click="downloadinvoice(invoice.invoice_no)"
                        >
                          Print / Download
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--/ Invoice Footer -->
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive } from "vue";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";

export default {
  name: "Invoice",

  setup() {
    const toast = useToast();
    const route = useRoute();
    const errors = ref([]);
    const customer = ref([]);
    const customeraddress = ref([]);
    const invoice = ref([]);
    const invoicepayment = ref([]);
    const invoicecoupon = ref([]);

    onMounted(() => {
      const pageid = route.params.key;
      // console.log(pageid);
      getInvoiceDetails(pageid);
    });

    const getInvoiceDetails = async (key) => {
      try {
        let res = await axios.get("/getinvoice/" + key);
        invoice.value = res.data;
        invoicepayment.value = res.data.paymentdetails;
        if (res.data.coupon != null) {
          invoicecoupon.value = res.data.coupon;
        }

        customer.value = res.data.customer;
        customeraddress.value = customer.value.address;
        // console.log(res.data);
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }
    };

    const downloadinvoice = async (key) => {
      // window.location.href = "download-invoice/" + key;

      try {
        let res = await axios.get("/download-invoice/" + key, {
          responseType: "blob"
        });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf");
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        if (e.response.status == 422) {
          var data = [];
          for (const key in e.response.data.errors) {
            data.push(e.response.data.errors[key][0]);
          }
          errors.value = data;
        }
      }

      // axios({
      //   url: "/cover-letter/generate/" + file.id,
      //   method: "GET",
      //   responseType: "blob", // important
      // }).then((response) => {
      //   const url = window.URL.createObjectURL(new Blob([response.data]));
      //   const link = document.createElement("a");
      //   link.href = url;
      //   link.setAttribute("download", "file.pdf");
      //   document.body.appendChild(link);
      //   link.click();
      // });
    };

    return {
      invoice,
      invoicepayment,
      invoicecoupon,
      customer,
      customeraddress,
      errors,
      toast,
      downloadinvoice,
    };
  },
};
</script>


<style lang="scss">
</style>