<template>
  <!-- main content -->
  <div class="content-wrapper d-flex align-items-center auth px-0">
    <div class="row w-100 mx-0">
      <div class="col-lg-8 mx-auto">
        <div class="auth-form-light text-center py-5 px-4 px-sm-5" v-if="!checkotp">
          <div class="brand-logo">
            <img v-bind:src="logo" :style="'width:150px'" />
          </div>
          <h4 class="fw-light">Register for new account</h4>
          <!-- <h6 class="fw-light">Register for new account</h6> -->
          <VeeForm class="pt-3" @submit="onSubmit">
            <div class="form-group">
              <VeeField name="fullname" type="text" class="form-control form-control-lg" placeholder="Full name"
                v-model="register.fullname" rules="required" />
              <ErrorMessage name="fullname" />
            </div>

            <div class="input-group mb-4 mr-sm-2">
              <div class="input-group-prepend">
                <div class="input-group-text" style="height: 100%">88</div>
              </div>
              <VeeField name="mobileno" type="number" class="form-control form-control-lg" placeholder="Mobile No"
                v-model="mobile_no" :rules="validatePhone" /><br>
              <ErrorMessage name="mobileno" />
            </div>

            <div class="form-group">
              <VeeField name="email" type="text" class="form-control form-control-lg" placeholder="email (optional)"
                v-model="register.email" rules="" />
              <ErrorMessage name="email" />
            </div>
            <div class="form-group">
              <VeeField name="password" type="password" class="form-control form-control-lg" v-model="register.password"
                placeholder="Password" rules="required|min:4" id="password" />
              <ErrorMessage name="password" />
            </div>
            <div class="form-group">
              <VeeField name="password_conf" type="password" class="form-control form-control-lg"
                v-model="register.password_confirmation" placeholder="Confirm password" :rules="validatePassword" />
              <ErrorMessage name="password_conf" />
            </div>
            <div class="mt-3">
              <button class="
                  btn btn-block btn-primary btn-lg
                  font-weight-medium
                  auth-form-btn
                " type="submit">
                Register
              </button>
              <a :href="'/'" class="btn btn-light btn-lg">Cancel</a>
            </div>
          </VeeForm>
        </div>

        <div class="auth-form-light text-center py-5 px-4 px-sm-5" v-if="checkotp">
          <div class="brand-logo">
            <img v-bind:src="logo" />
          </div>
          <h4 class="fw-light">Please verify OTP</h4>
          <!-- <h6 class="fw-light">Register for new account</h6> -->
          <VeeForm class="pt-3" @submit="checkOTP">
            <div class="form-group">
              <VeeField name="otpcode" type="text" class="form-control form-control-lg" placeholder="OTP"
                v-model="verifyotp.otpcode" rules="required|min:4" />
              <ErrorMessage name="fullname" />
            </div>

            <div class="mt-3">
              <button class="
                  btn btn-block btn-primary btn-lg
                  font-weight-medium
                  auth-form-btn
                " type="submit">
                Verify OTP
              </button>
              <a :href="'/'" class="btn btn-light btn-lg">Cancel</a>
            </div>
          </VeeForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { useToast } from "vue-toastification";
export default {
  name: "Register",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  props: {
    msg: String,
  },
  setup() {
    const toast = useToast();
    const validatePhone = async (value) => {
      if (!value) {
        return "Phone no is required";
      }
      if (value.length == 11) {
        let res = await axios.get("/checkphone/" + value);
        if (res.data) {
          return "This number is already in use.";
        }
      }
      return true;
    };

    const validatePassword = async (value) => {
      if (!value || value.length < 4) {
        return 'This field is required';
      }
      const pass = document.getElementById('password').value;

      if (value !== pass) {
        return 'Password missmatch';
      }
      return true;
    };

    return { toast, validatePhone, validatePassword };
  },
  data() {
    return {
      count: 0,
      logo: "../assets/img/logo_200.png",
      mobile_no: "",
      register: {
        fullname: "",
        mobileno: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      checkotp: false,
      generateotp: {
        verify_phone_no: "",
      },
      verifyotp: {
        mobileno: "",
        otpcode: ""
      }
    };
  },
  methods: {

    onSubmit() {
      this.generateotp.verify_phone_no = this.mobile_no;

      this.axios
        .post("/generate-otp", this.generateotp)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === 'success') {
            this.checkotp = true;
            this.toast.success(response.data.message, {
              timeout: 2000,
            });
            
          } else {
            this.toast.warning(response.data.message, {
              timeout: 2000,
            });
          }

          return;
        })
        .catch((error) => {
          
          this.toast.warning("Please check credentials.", {
            timeout: 2000,
          });
        });
    },

    checkOTP() {
      this.verifyotp.mobileno = this.mobile_no;
      this.axios
        .post("/verify-otp", this.verifyotp)
        .then((response) => {
          console.log(response.data);
          if (response.data.status == "success") {
            this.register.mobileno = this.mobile_no;
            this.axios
              .post("/register", this.register)
              .then((response) => {
                window.location.href = "home";
              })
              .catch((error) => {
                console.log("Please check credentials.");
                toast.warning("Please check credentials.", {
                  timeout: 2000,
                });
              });
          }
        })
        .catch((error) => {
          console.log("Wrong OTP.");
          this.toast.warning("Wrong OTP.", {
            timeout: 2000,
          });
        });
    }
  },

};
</script>
