<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Customers</h4>
          <p class="card-description">All <code>Customers</code></p>
          <div class="table-responsive">
            <ag-grid-vue
              style="width: 100%; height: 500px"
              class="ag-theme-alpine"
              :columnDefs="columnDefs"
              :rowData="customers"
              :pagination="true"
            >
            </ag-grid-vue>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- content-wrapper ends -->
</template>

<script>
import { ref, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { AgGridVue } from "ag-grid-vue3";

export default {
  name: "Customers",
  components: {
    AgGridVue,
  },

  setup() {
    const customers = ref([]);
    const toast = useToast();
    const getcustomers = async () => {
      let res = await axios.get("/admin/get-all-customers");
      customers.value = res.data;
    };

    const edituser = async (value) => {
      console.log("this is edit" + value);
    };

    const deactivecustomer = async (id) => {
      await axios.delete("/admin/deactive-customer/" + id);
      getcustomers();
      toast.success("Customer deactiveted successfully!", {
        timeout: 2000,
      });
    };

    const activecustomer = async (id) => {
      await axios.delete("/admin/active-customer/" + id);
      getcustomers();
      toast.success("Customer activeted successfully!", {
        timeout: 2000,
      });
    };

    onMounted(getcustomers());

    return {
      customers,
      toast,
      edituser,
      deactivecustomer,
      activecustomer,
      columnDefs: [
        { headerName: "Name", field: "name" },
        { headerName: "Username", field: "username" },

        {
          headerName: "Status",
          field: "status",
          cellRenderer: function (params) {
            if (params.data.status == 0) {
              return 'Inactive';
            }else{
              return 'Active';
            }
          },
        },

        {
          headerName: "Activity",
          field: "activity",
          cellRenderer: "btnCellRenderer",
          cellRenderer: function (params) {
            var input = document.createElement("button");
            input.setAttribute("class", "btn btn-sm btn-danger");
            if (params.data.status == 0) {
              input.innerHTML = "Active";
              input.addEventListener("click", function (event) {
                activecustomer(params.data.id);
              });
            } else {
              input.innerHTML = "Deactive";
              input.addEventListener("click", function (event) {
                deactivecustomer(params.data.id);
              });
            }

            return input;
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
